import React, { useEffect, useState } from 'react';
import '../../styles/booking.css'


export default function BookingStateRow({step,clicked}) {


    
    return (
        <div className='bookingStateRow'>
            <div className={`bookingStateCircle ${step<1?'disabled':''}`} onClick={()=>clicked(1)}>1</div>
            <div className={`bookingStateText ${step==1?'current':''}`}>Choose Pricing</div>

            <div className={`bookingStateLine ${step<2?'disabled':''}`}/>

            <div className={`bookingStateCircle ${step<2?'disabled':''}`} onClick={()=>clicked(2)}>2</div>
            <div className={`bookingStateText ${step==2?'current':''}`}>Review Order</div>

            <div className={`bookingStateLine ${step<3?'disabled':''}`}/>

            <div className={`bookingStateCircle ${step<3?'disabled':''}`} onClick={()=>clicked(3)}>3</div>
            <div className={`bookingStateText ${step==3?'current':''}`}>Book Sessions</div>

            <div className={`bookingStateLine ${step<4?'disabled':''}`}/>

            <div className={`bookingStateCircle ${step<4?'disabled':''}`} onClick={()=>clicked(4)}>4</div>
            <div className={`bookingStateText ${step==4?'current':''}`}>Payment</div>
        </div>
    )
}
