import React, { useEffect, useState } from 'react';
import '../../../styles/dashboard.css'
import NoClasses from '../Tiles/NoClasses';
import PracticeTestTile from '../Tiles/PracticeTestTile';
import axios from "axios";
import { auth } from '../../..';
import { getAuth,onAuthStateChanged} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { BsPlus } from 'react-icons/bs';



export default function TutorTestSection({}) {

    const [tests,setTests] = useState([])
    const [completedTests,setCompletedTests] = useState([])
    const navigate = useNavigate();

    const baseURL = process.env.REACT_APP_BASEURL;

    useEffect(() => {

        const unsubscribe = onAuthStateChanged(auth, (user) => {
          if (user) {

                getTests()
      
          } else {
            console.log("Auth change", false)
            
          }
        })
        return () => unsubscribe()
      
    },[])

    


    const getTests = () => {

        const tutorID = auth.currentUser.uid


        axios.post(baseURL+'getPracticePacks',{
            
                business:"Routeen",
                tutors:[tutorID]
            
        }).then((response) => {
            let resVar = response.data
            if(resVar!=="IGNORE")
            {
                setTests(resVar.packs)

                var IDarray = []
                resVar.packs.forEach(pack => {
                    IDarray.push(pack.id)
                });

                getCompletedTests(IDarray)
            }
        })
    }

    
    const getCompletedTests = (testIDs) => {
        

        axios.get(baseURL+'getTestsByIDs',{
            params:{
                testIDs:testIDs
            }
        }).then((response) => {
            let resVar = response.data
            let tests = resVar.tests
            let completed = []

            tests.forEach(pack => {
                pack.forEach(test => {
                    if(test.complete){completed.push(test)}
                });
            });

            setCompletedTests(completed)

            console.log("Complterted",completed)

        })
    }



    const showTests = () => {
        if(tests.length === 0){
            return <span>You have no tests</span>
        }else{
            var packArray = []
            tests.forEach(pack => {
                packArray.push(<PracticeTestTile type={"pack"} obj={pack} tutor={true} clicked={()=>goToTestEditor(pack)}/>)
            });

            packArray.push(<div className='addTestButton' onClick={()=>goToTestEditor(null)}>
                <span>Add Test</span>
                <BsPlus className='addTestIcon'/>
                </div>)

            return <div className="classesTileGrid">{packArray}</div>
            
        }
    }


    
    
    const showCompletedTests = () => {
        if(completedTests.length === 0){
            return <NoClasses type={'completedTests'} isTutor={true}/>
        }else{
            var testArray = []
            completedTests.forEach(test => {
                testArray.push(<PracticeTestTile type={"complete"} obj={test} clicked={()=>continueTest(test)}/>)
                // testArray.push(<button onClick={()=>continueTest(test)}>{test.packName}</button>)
            });

            return <div className="classesTileGrid">{testArray}</div>
        }
    }


    const goToTestEditor = (test) => {
        console.log("TETST",test)
        navigate(`../editTest`, { replace: true, state:{test:test}});
    }

    
    const continueTest = (test) => {
        navigate(`../practice`, { replace: true, state:{test:test}});
    }
    

    return(

        <div className='tutorTestSection'>


        <div className="upcomingClasses">
            <span className="dashboardSubTitle">Your Packs</span>
            
            {showTests()}
                
        </div>

        <div className="upcomingClasses">
            <span className="dashboardSubTitle">Completed Tests</span>
            
            {showCompletedTests()}
                
        </div>

           
        </div>
    )
}