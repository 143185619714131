
import {useNavigate,useLocation,useParams} from 'react-router-dom';

import React, { useEffect, useState } from 'react';

import '../styles/dashboard.css'
import ClassTile from "../components/Dashboard/Tiles/ClassTile";
import NoClasses from "../components/Dashboard/Tiles/NoClasses";
import axios from "axios";

import SchedulerPopUp from "../components/Dashboard/SchedulerPopUp";
import PracticeTestTile from "../components/Dashboard/Tiles/PracticeTestTile";
import { JitsiMeeting } from "@jitsi/react-sdk";
import AvailabilitySection from "../components/Dashboard/AvailabilitySection";
import TutorTestSection from "../components/Dashboard/TutorTests/TutorTestSection";
import * as constants from '../constants';
import AddTutorClass from '../components/Dashboard/AddTutorClass';
import CreditBalance from "../components/Dashboard/Tiles/CreditBalance";


export default function Dashboard({routeChanged}) {

    const baseURL = process.env.REACT_APP_BASEURL;

    const navigate = useNavigate();

    const [payExpanded, setPayExpanded] = useState(false)
    const [allLessons, setAllLessons] = useState([])
    const [scheduledLessons, setScheduledLessons] = useState([])
    const [unscheduledLessons, setUnscheduledLessons] = useState([])

    const [userName, setUserName] = useState("")
    const [isTutor, setIsTutor] = useState(false)
    const [tutor, setTutor] = useState(false)

    const [payments,setPayments] = useState(null)

    const [showScheduler, setShowScheduler] = useState(false)
    const [schedulerLesson,setSchedulerLesson] = useState(false)

    const [packs,setPacks] = useState([])
    const [currentTests,setCurrentTests] = useState([])
    const [completedTests,setCompletedTests] = useState([])
    
    const [showMeeting,setShowMeeting] = useState(false)


    const [creditBalance, setCreditBalance] = useState(localStorage.getItem("creditBalance" || 0))
    const [earnings, setEarnings] = useState(0)



    
// #region SHOW COMPONENTS

    

    const showScheduledLessons = () => {

        if(scheduledLessons.length === 0){
            return <NoClasses type={'scheduled'} hasPending={true} routeChanged={routeChanged} isTutor={isTutor}/>
        }else{
            var lessonArray = []
            scheduledLessons.forEach(lesson => {
                lessonArray.push(<ClassTile classObj={lesson} buttonClicked ={()=>enterLesson(lesson.id)} isTutor={isTutor} cancelled={cancelLesson}/>)
            });
            return <div className="classesTileGrid">{lessonArray}</div>
        }
        
        
    }

    const showUnscheduledLessons = () => {

        if(unscheduledLessons.length === 0){
            return <NoClasses type={'unscheduled'} hasPending={false} routeChanged={routeChanged} />
        }
        else{
            var lessonArray = []
            unscheduledLessons.forEach(lesson => {
                lessonArray.push(<ClassTile classObj={lesson} buttonClicked={() => scheduleClicked(lesson)}/>)
            });
            return <div className="classesTileGrid">{lessonArray}</div>
        }
        
    }

    const showCollapsedPayments = () => {
        if(payments !== null)
        {
            const overall = {
                type:'paymentTotal',
                price:"$"+(payments.overall/100),
                month:payments.month,
            }

            return(<ClassTile classObj={overall} buttonClicked={expandPayments}/>)
        }
        else{ return null;}
    }


    const showExpandedPayments = () => {
        const paymentComps = []
        
        if(payments !== null)
        {


            let paymentArray = payments.payments

            for(var i=0;i<paymentArray.length;i++)
            {
                let paymentVar = paymentArray[i]
                
            

                var payment = {
                    type:'payment',
                    price:"$"+(paymentVar.price/100),
                    amount: paymentVar.classes+ ' Classes Delivered',
                    person:"("+paymentVar.student+")"
                }
                
                console.log("PAYMENT",payment)

                if(i === paymentArray.length-1)
                {
                    console.log("ADDINGG")

                    payment["type"] = 'paymentFinal'
                    payment["month"] = payments.month
                    payment["total"] = "$"+(payments.overall/100)

                    console.log("PAYMENT2",payment)
                }

                paymentComps.push(<ClassTile classObj={payment}/>)
            }

        }

        return paymentComps
    }


// #endregion

// #region BUTTONS CLICKED
    const scheduleClicked = (lesson) => {
        setSchedulerLesson(lesson)
        setShowScheduler(true)
        window.scrollTo(0, 0)
        document.body.style.overflow = "hidden"
    }

    const expandPayments = () => {
        setPayExpanded(true)
    }

    const collapsePayments = () => {
        setPayExpanded(false)
    }

    const closePopUp = () => {
        setShowScheduler(false)
        document.body.style.overflow = "auto"
    }

// #endregion


// #region WEB REQUESTS

    const loadLessonsFromDB = (uid,isTutor) => {


        axios.post(baseURL+'getLessons',{
            uid:uid,
            isTutor:isTutor,
            business:constants.BUSINESS,
        }).then((response) => {
            let resVar = response.data
            
            localStorage.setItem("lessons",JSON.stringify(resVar))
            setAllLessons(resVar)
            organiseLessons(resVar)
        })

    }

    const loadTutorUnscheduledLessons = (tutorID) => {


        axios.get(baseURL+'getTutorUnscheduledLessons',{
            params:{
                tutor:tutorID
            }
        }).then((response) => {
            let resVar = response.data
            console.log("Lessons", resVar)
            setUnscheduledLessons(resVar.lessons)
        })

    }


    const loadCreditBalanceFromDB = (uid) => {


        axios.get(baseURL+'checkCreditBalance',{
            params:{
                tutor:uid,
            }
        }).then((response) => {
            let resVar = response.data
           setCreditBalance(resVar.balance)
           localStorage.setItem("creditBalance",resVar.balance)
        })

    }

    const loadPaymentsFromDB = (uid) => {


        axios.get(baseURL+'getTutorPayments',{
            params:{
                tutorID:uid,
            }
        }).then((response) => {
            let resVar = response.data
            console.log("Payments", resVar)
            setPayments(resVar)
            setEarnings(resVar.overall)
        })

    }
    

// #endregion


// #region LESSONS SECTION

    const addClass = (lesson) => {

        let allLess = allLessons
        let lessons = allLess.concat([lesson])
        
        localStorage.setItem("lessons",JSON.stringify(lessons))
        setAllLessons(lessons)
        organiseLessons(lessons)
    }


    const organiseLessons = (lessons) => {
                
        var scheduled = []
        var unscheduled = []
        
        lessons.forEach(lesson => {
            if(lesson.scheduled){
                lesson.timestamp = new Date(lesson.startTime).getTime()
                scheduled.push(lesson)
            }
            else{unscheduled.push(lesson)}
        });
        
        scheduled.sort((a, b) => parseFloat(a.timestamp) - parseFloat(b.timestamp));

        let today = new Date()

        scheduled = scheduled.filter(function (item)
        {
            return item.timestamp > today.getTime()
        })

        console.log("SCHEDULED", scheduled)
        setScheduledLessons(scheduled)
        setUnscheduledLessons(unscheduled)
    }

    const enterLesson = (lessonID) => {
        console.log("LESSON CLICKED",lessonID)
        // const config = {
        //     name: "Your Lesson",
        //     meetingId: lessonID,
        //     apiKey: "fd9282b2-b4d9-4510-b726-45eeb7606387",
      
        //     containerId: null,
      
        //     micEnabled: true,
        //     webcamEnabled: true,
        //     participantCanToggleSelfWebcam: true,
        //     participantCanToggleSelfMic: true,
        //     redirectOnLeave: "http://localhost:3000/dashboard",
      
        //     chatEnabled: true,
        //     screenShareEnabled: true,
      
           
        //   };
      
        //   const meeting = new VideoSDKMeeting();
        //   meeting.init(config);

        setShowMeeting(true)


    }

    

    const cancelLesson = (id) => {

        let updatedList = scheduledLessons.filter((obj) => obj.id !== id);
        setScheduledLessons(updatedList)
    }

    

    const lessonScheduled = (lessonID,dateTime) => {
        
        allLessons.forEach(lesson => {
            if(lesson.id === lessonID)
            {
                lesson.scheduled = true
                lesson.startTime = dateTime
            }
        });

        console.log("ALL LESSONs",allLessons)

        localStorage.setItem("lessons",JSON.stringify(allLessons))
        setAllLessons(allLessons)
        organiseLessons(allLessons)

        setShowScheduler(false)
        document.body.style.overflow = "auto"
    }

// #endregion

// #region PRACTICE SECTION


const showPacks = () => {
    if(packs.length === 0){
        return <NoClasses type={'packs'} isTutor={isTutor}/>
    }else{
        var packArray = []
        packs.forEach(pack => {
            packArray.push(<PracticeTestTile type={"pack"} obj={pack} clicked={()=>createStudentTest(pack.id,pack.name)}/>)
        });

        return <div className="classesTileGrid">{packArray}</div>
        
    }
}


const showCurrentTests = () => {
    if(currentTests.length === 0){
        return <NoClasses type={'tests'} isTutor={isTutor}/>
    }else{
        var testArray = []
        currentTests.forEach(test => {
            testArray.push(<PracticeTestTile type={"currentTest"} obj={test} clicked={()=>continueTest(test)}/>)
        });

        return <div className="classesTileGrid">{testArray}</div>
        
    }
}


const showCompletedTests = () => {
    if(completedTests.length === 0){
        return <NoClasses type={'completedTests'} isTutor={isTutor}/>
    }else{
        var testArray = []
        completedTests.forEach(test => {
            testArray.push(<PracticeTestTile type={"complete"} obj={test} clicked={()=>continueTest(test)}/>)
        });

        return <div className="classesTileGrid">{testArray}</div>
    }
}

const createStudentTest = (packID,packName) => {
    
    let user = JSON.parse(localStorage.getItem("user"))
    axios.get(baseURL+'createStudentTest',{
        params:{
            packID:packID,
            packName:packName,
            student:user.uid
        }
    }).then((response) => {
        let resVar = response.data.test
        currentTests.push(resVar)
        setCurrentTests(currentTests)


        navigate(`../practice`, { replace: true, state:{test:resVar}});
    })
}

const getPacks = () => {
    let user = JSON.parse(localStorage.getItem("user"))
    axios.post(baseURL+'getPracticePacks',{
        
            business:"Routeen",
            tutors:user.previousTutors
        
    }).then((response) => {
        let resVar = response.data
        if(resVar!=="IGNORE")
        {
            setPacks(resVar.packs)
        }
    })
}

const getStudentsTests = () => {
    
    let user = JSON.parse(localStorage.getItem("user"))
    
    axios.get(baseURL+'getTestsForStudent',{
        params:{
            studentID:user.uid
        }
    }).then((response) => {
        let resVar = response.data
        let tests = resVar.tests
        let current = []
        let completed = []

        tests.forEach(test => {
            if(test.complete){completed.push(test)}
            else{current.push(test)}
        });

        setCompletedTests(completed)
        setCurrentTests(current)

    })
}

const continueTest = (test) => {
    navigate(`../practice`, { replace: true, state:{test:test}});
}


// #endregion


// #region OTHER
    

    const getTimeZone = () => {
        const offset = (new Date()).getTimezoneOffset();

        let hours = offset/-60

        if(hours < 0){return "GMT"+hours}
        else if(hours > 0){return "GMT+"+hours}
        else{return "GMT"}

    }

// #endregion

    
    

    useEffect(() => {
        var user = JSON.parse(localStorage.getItem("user"))
        console.log("GET USER")


        if(user !== null)
        {
            if(userName === ""){
                setUserName(user.name)
                setIsTutor(user.isTutor)
                
                if(user.isTutor){
                    setTutor(user)
                    loadPaymentsFromDB(user.uid)
                    loadCreditBalanceFromDB(user.uid)
                    loadTutorUnscheduledLessons(user.uid)
                }
                loadLessonsFromDB(user.uid,user.isTutor)
            }

            getPacks()
            getStudentsTests()
        }
        
    },[localStorage.getItem("user")])

    
    
    
    if(allLessons === null)
    {

        let lessonsLS = localStorage.getItem("lessons")
        
        if(lessonsLS === null)
        {
            
            
            
        }
        else{
            console.log("LESSONS LS",lessonsLS)
            let lessons = JSON.parse(lessonsLS)
            console.log("LESSONS",lessons)
            setAllLessons(lessons)
            organiseLessons(lessons)
        }

    }


    



    




    







    return(
        <div className="dashboardScreen">

            <div className="dashboardScreenContainer">

                <div className="dashboardSection1">
                    <div className="dashSec1Column">
                        <span className="dashboardTitle">Welcome back, {userName}!</span>
                        <span>Your progress this week is Awesome. Let's keep it up!</span>
                    </div>
                    <img className="dashboardImage" src="assets/dashboardImg1.png"/>
                </div>

                {(isTutor && !constants.MULTIPLE) && <CreditBalance balanceP={creditBalance} earningsP={earnings}/>}

                {isTutor && <AvailabilitySection tutor={tutor}/>}

                <div className="upcomingClasses">
                    <span className="dashboardSubTitle">Upcoming Classes</span>
                    <span className="timezoneSpan">All times are in {getTimeZone()}</span>
                    
                        {showScheduledLessons()}
                    
                    
                </div>

                {isTutor && <AddTutorClass tutor={tutor} added={addClass}/>}


                {!isTutor && <div className="upcomingClasses">
                    <span className="dashboardSubTitle">Unscheduled Classes</span>
                    
                        {showUnscheduledLessons()}
                    
                    
                </div>}

                {isTutor && <div className="upcomingClasses">
                    <span className="dashboardSubTitle">Upcoming Payments</span>
                    {!payExpanded && <div className="classesTileGrid">
                        {showCollapsedPayments()}
                    </div>}
                    {payExpanded && <div className="classesTileColumn">
                        <span onClick={collapsePayments}>Show Less</span>
                        {showExpandedPayments()}
                        </div>}
                </div>}


                {!isTutor && <div className="upcomingClasses">
                    <span className="dashboardSubTitle">Practice Packs</span>
                    
                    {showPacks()}
                        
                </div>}

                {isTutor && <TutorTestSection />}

                
                {!isTutor && <div className="upcomingClasses">
                    <span className="dashboardSubTitle">Practice Tests</span>
                    
                    {showCurrentTests()}
                        
                </div>}

                
                {!isTutor && <div className="upcomingClasses">
                    <span className="dashboardSubTitle">Completed Tests</span>
                    
                        {showCompletedTests()}
                        
                </div>}

            </div>

            {showScheduler && <SchedulerPopUp lesson={schedulerLesson} closePopUp={closePopUp} lessonScheduled={lessonScheduled}/>}

            {showMeeting && <div className='jitsiMeeting'><JitsiMeeting
                
                // domain = { 'https://routeen-d57ed.web.app/' }
                roomName = "Test Meeting"
                configOverwrite = {{
                    startWithAudioMuted: true,
                    disableModeratorIndicator: true,
                    startScreenSharing: true,
                    enableEmailInStats: false,
                    enableClosePage: true,
                }}
                interfaceConfigOverwrite = {{
                    DISABLE_JOIN_LEAVE_NOTIFICATIONS: true
                }}
                userInfo = {{
                    displayName: 'caolangm'
                }}
                onApiReady = { (externalApi) => {
                    // here you can attach custom event listeners to the Jitsi Meet External API
                    // you can also store it locally to execute commands
                    externalApi.addEventListener("videoConferenceLeft ", ()=>{
                            console.log("LEFT")
                    });

                } }

                onReadyToClose={()=>{
                    setShowMeeting(false)
                }}
                
                getIFrameRef = { (iframeRef) => { iframeRef.style.width = '100%';  iframeRef.style.height = '100%'; } }
            /></div>}

        </div>
    )
}


