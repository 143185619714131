import PlanTile from '../components/Dashboard/Tiles/PlanTile'
import '../styles/home.css'
import React, { useEffect, useState } from 'react';
import PaymentPopUp from '../components/General/PaymentPopUp';


export default function BuyClasses() {


    const [type, setType] = useState(false)
    const [showPopUp, setShowPopUp] = useState(false)

    const planTileClicked = (type) => {
        setType(type)
        setShowPopUp(true)
    }

    return (
        
        <div className="homeScreen">
            <div className="homeScreenContainer">

                <span className='buyClassesTitle'>Get More Classes</span>
                <span className='buyClassesSubtitle'>New Classes Will Be Added To Your Current Balance Of Classes</span>
                
                <div className='buyScreenPlanRow'>
                    <PlanTile type={"Starter Pack"} clicked={()=>planTileClicked("starter")}/>
                    <PlanTile type={"Professional"} clicked={()=>planTileClicked("professional")}/>
                </div>
            </div>

            {showPopUp && <PaymentPopUp type={type} closePopUp={()=>setShowPopUp(false)}/>}
        
        </div>

    )
}