
import React, { useEffect, useState } from 'react';
import '../../styles/home.css'
import { useNavigate } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";

export default function TeacherTile({tutor,raised}) {

    const [showInfo, setShowInfo] = useState(false);
    const navigate = useNavigate();


    const tileClicked = (event) => {
        let target = event.target.id
        console.log("TARGET",target)
        window.scrollTo(0, 0)

        if(target === "discoverBtn")
        {
            goToProfile()
        }
        else{
            goToProfile()
        }
    }

    const goToProfile = () =>
    {
        navigate(`../tutor/`+tutor.id, { replace: true, state:{tutor:tutor}});
    }


    return(
        <div className={`featuredTeacherTile ${raised?'raised':''}`}>
            <img className="fttImg" src={tutor.photos[0]}  onClick={goToProfile}/>

            <div className="fttInfo"
            onMouseEnter={() => { setTimeout(() => setShowInfo(true), 100);}}
            onMouseLeave={() => {
                setTimeout(() => setShowInfo(false), 50);
                setTimeout(() => setShowInfo(false), 101);
            }}
            onClick={(event)=>tileClicked(event)}
            >
                <div className="fttRow">
                    <span className="fttBold">${tutor.startingRate}/hr</span>
                    <div className="fttRating">
                        <img className="fttStar" src="assets/Star.png"/>
                        <span>{tutor.rating}</span>
                    </div>
                </div>
                <span className="fttBold">{tutor.name}</span>
                <span className="fttSubText">Expert in {tutor.subjects[0]}</span>
                <span className="fttSubText">University of Oxford, UK</span>
                {showInfo && <span className="fttSubText">{tutor.bio}</span>}
                {(showInfo) && <div id="discoverBtn" className="section1HSBtn discoverTutors teacherTile"><span id="discoverBtn">Discover More</span><BsArrowRight id="discoverBtn"/></div>}
            </div>
        </div>
    )
}


