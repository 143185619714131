import React, { useEffect, useState } from 'react';
import '../../styles/footer.css'
import { useNavigate } from "react-router-dom";


export default function FAQs({}) {

    const navigate = useNavigate();

    const [faqChosen, setFaqChosen] = useState(-1)


    const toggleFaq = (index) => {


        if(index == faqChosen){setFaqChosen(-1)}
        else{setFaqChosen(index)}
        
    }

    return(
        <div className='section5DTfaq'>
            
            <div className='faq'>
                <div className='faqRow' onClick={() => toggleFaq(0)}>
                    <span className='faqTitle'>How are the tuitions conducted</span>
                    <img className={`faqArrow ${faqChosen==0?'chosen':''}`} src='assets/faqArrow.png'/>
                </div>
                {faqChosen==0 && <span className='faqSubText'>All of the tuitions on routeen are conducted via video conferencing on our own platform, the teachers might also use supplemental tools to teach if needed. </span>}
            </div>

            <div className='faqLine' />

            
            <div className='faq'>
                <div className='faqRow' onClick={() => toggleFaq(1)}>
                    <span className='faqTitle'>What payment methods do you accept?</span>
                    <img className={`faqArrow ${faqChosen==1?'chosen':''}`} src='assets/faqArrow.png'/>
                </div>
                {faqChosen==1 && <span className='faqSubText'>All of the tuitions on routeen are conducted via video conferencing on our own platform, the teachers might also use supplemental tools to teach if needed. </span>}
            </div>

            <div className='faqLine' />
            
            
            <div className='faq'>
                <div className='faqRow' onClick={() => toggleFaq(2)}>
                    <span className='faqTitle'>What is your refund policy?</span>
                    <img className={`faqArrow ${faqChosen==2?'chosen':''}`} src='assets/faqArrow.png'/>
                </div>
                {faqChosen==2 && <span className='faqSubText'>All of the tuitions on routeen are conducted via video conferencing on our own platform, the teachers might also use supplemental tools to teach if needed. </span>}
            </div>

            <div className='faqLine' />
            
            
            <div className='faq'>
                <div className='faqRow' onClick={() => toggleFaq(3)}>
                    <span className='faqTitle'>How am I billed if I want a customised package</span>
                    <img className={`faqArrow ${faqChosen==3?'chosen':''}`} src='assets/faqArrow.png'/>
                </div>
                {faqChosen==3 && <span className='faqSubText'>All of the tuitions on routeen are conducted via video conferencing on our own platform, the teachers might also use supplemental tools to teach if needed. </span>}
            </div>

            <div className='faqLine' />

            

        </div>
    
    
    )
}