import React, { useEffect, useState } from 'react';
import '../../styles/booking.css'
import TutorPriceCell from '../TutorProfile/TutorPricingCell';
import BookingInputs from './BookingInputs';
import BookingStateRow from './BookingStateRow';
import useRazorpay from "react-razorpay";
import axios from "axios";

import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import PaymentComponent from '../General/PaymentComponent';
import { getAuth  } from "firebase/auth";
import { auth } from '../..';
import * as constants from '../../constants';


export default function BookingPopUp({close, tutor,chosenOptionP,priceOptions,stepP,couponP}) {
    
    const stripePromise = loadStripe('pk_live_51LuBnCSC11nmIG705kcgWxVYMHgJYiwWZFf705p99We05ciAoXVssmmdyxAtjCmwetBnl13tyBduxIz2R22scF4Y008v5QaBcD',
        {stripeAccount:constants.STRIPE}
    );

    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

    const Razorpay = useRazorpay();
    const baseURL = process.env.REACT_APP_BASEURL;
    

    const [step, setStep] = useState(stepP)
    const [paymentOrder, setPaymentOrder] = useState(null)
    const [chosenOption, setChosenOption] = useState(chosenOptionP)
    

    const [loadingAvailability,setLoadingAvailability] = useState(false)
    const [availability, setAvailability] = useState(null)
    const [groupClasses, setGroupClasses] = useState(null)
    


    const [inputArray, setInputArray] = useState([{key:0, date:undefined,time:undefined,error:false}])

    const [paymentLoading, setPaymentLoading] = useState(false)

    const [couponID, setCouponID] = useState("")
    const [couponError, setCouponError] = useState("")
    const [coupon, setCoupon] = useState(couponP)
    const [discountedPrice, setDiscountedPrice] = useState(null)


    const [customerID,setCustomerID] = useState("cus_4589")
    const [paymentID,setPaymentID] = useState("pm_1234")
    const [payMethod,setPayMethod] = useState("visa")
    const [options, setOptions] = useState(null)
    const [setupIntentID, setIntentID] = useState("")
    const [useSavedCard,setUseSaveCard] = useState(false)
    const [checkingPayment,setCheckingPayment] = useState(false)

    const [showPaySuccess,setShowPaySuccess] = useState(false)

    const [newCustomer,setNewCustomer] = useState(false)
    const [addressText,setAddressText] = useState("")

    const appearance = {
        theme: 'flat',
        variables:{
            
        }
        
      };



    useEffect(() => {

        let couponLS = localStorage.getItem("coupon")
        console.log("COUPON LS",couponLS)
        if(couponLS !== null)
        {
            let couponJSON = JSON.parse(couponLS)
            setCoupon(couponJSON)
        }

        let referral = localStorage.getItem("referral")
        if(referral !== null && referral.contains("DIS"))
        {
            setCouponID(referral)
            searchForCoupon(referral)
        }
    },[])

    useEffect(() => {

        if(couponP !== null)
        {
            if(couponP.type ==="percentage")
            {
                setDiscountedPrice(chosenOption.overallPrice * ((100-couponP.discount)/100))
            }
            else{
                setDiscountedPrice(chosenOption.overallPrice - (couponP.discount*100))
            }
        
        }

    },[couponP])

    useEffect(() => {

        if(chosenOption !== null)
        {
            getTutorAvailability()
        }

    },[chosenOption])



    const getTutorAvailability = () => {
        setLoadingAvailability(true)
        axios.get(baseURL+'getTutorAvailability',{
            params:{
                tutorID:tutor.id,
                timezone: timeZone,
                duration:chosenOption.duration
            }
        }).then((response) => {
            setLoadingAvailability(false)
            setAvailability(response.data.times)
            setGroupClasses(response.data.classes)

        })
    }
    if(availability === null && !loadingAvailability && chosenOption!== null){getTutorAvailability()}

    const setUpPayment = () => {
        // setPaymentLoading(true)
        // axios.get(baseURL+'createRazorpayOrder',{
        //     params:{
        //         price:chosenOption.overallPrice,
        //     }
        // }).then((response) => {
        //     let resVar = response.data
        //     setPaymentOrder(resVar)
        //     console.log("Payment ORder", resVar)
        //     setPaymentLoading(false)
        // })
        getCustomerStripeID()

    }
    

    
    const getCustomerStripeID = async() => 
    {
        // loadingStatus(true)
        // const auth =  await getAuth();
        console.log("AUTH",auth)
        const uid = auth.currentUser.uid;

        return new Promise(async (resolve, reject) => {
            axios.get(baseURL+'getCustomersStripeID',{
            
                params:{
                    authID:uid,
                    business:constants.BUSINESS,
                    account:constants.STRIPE  
                }

            }).then((response) => {
                let resVar = response.data
                
                let cusID = resVar.customerID

                setNewCustomer(resVar.new)
                setCustomerID(cusID)
                createPaymentIntent(cusID)
            })
        })
    }

    const createPaymentIntent = (cusID) => {

        axios.get(baseURL+'createPaymentIntent',{
            params:{
                customer: cusID,
                // amount: coupon===null?chosenOption.overallPrice:discountedPrice
                amount:chosenOption.overallPrice,
                account: constants.STRIPE
            }
        }).then( (response) => {
        
          const data = response.data;
          
          let setUpID = data.paymentIntent.client_secret
          console.log("Setup ID",setUpID)

          

          setOptions({clientSecret:setUpID,appearance:appearance})
            
          setIntentID("")
          setIntentID(setUpID)
          setPaymentID(data.paymentIntent.id)
        
        });

    }

    
    const updateTutorCreditBalance = (amount) => {
        axios.get(baseURL+'classSheduledCredit',{
            params:{
                tutor: constants.TUTOR_ID,
                amountClasses: amount
            }
        }).then( (response) => {
        
            
        });
    }

    
    const addBillingAddress = () => {

        let split = addressText.split(",")

        let country = split.length>1?split[split.length-1]:"N/A"

        axios.get(baseURL+'updateCustomerAddress',{
            params:{
                customer: customerID,
                address:split[0],
                country:country
            }
        }).then( (response) => {
        
          const data = response.data;
          
          setCheckingPayment(true)
        
        });

    }


    const stripeResponse = (res)=>
    {
        if(res !== "Success"){setCheckingPayment(false)
                // setLoadingText("Checking card")
        }
        else{
            onSuccessfulPayment()
        }
    }


    const onSuccessfulPayment = async () => {

        console.log("SUCCESSFUL PAYMENT")
        setShowPaySuccess(true)
        if(coupon!==null){
            localStorage.removeItem("referral")
            couponUsed()
        }
        const lessons = createLessonObjects(inputArray)
        addLessonsLocally(lessons)
        addLessonsToDB(lessons)
        updateTutorCreditBalance(inputArray.length)
    }

















    const closePopUp = () => {
        close(false,[])
    }
    
    const nextStep = async() => {
        var confirmed = true
        if(step === 3){confirmed = await checkBookingInputs()}
        if(confirmed)
        {
            setStep(step+1)
            if(step+1 === 3){setUpPayment()}
        }
        
    }

    const stepClicked = (stepP) => {
        if(stepP<step)
        {
            setStep(stepP)
        }
        
    }

    const getStep1 = () => {
        let step1 = []

        priceOptions.forEach(option => {
            step1.push(<TutorPriceCell bookClicked={() => step1OptionSelected(option)} priceOption={option} coupon={coupon}/>)
        });

        return step1
    }

    const step1OptionSelected = (priceOption) => {
        setChosenOption(priceOption)
        setStep(2)
    }
    
    const getStep2 = () => {
        let step2 = []

        let priceShown = coupon===null?chosenOption.overallPrice/100:discountedPrice/100
            
        step2.push(getStep2Row("Professor:",tutor.name))
        step2.push(getStep2Row("Pricing:",chosenOption.name +" ("+chosenOption.amountLesson+" Classes)"))
        step2.push(getStep2Row("Subject:",tutor.subjects))
        step2.push(getStep2Row("Fee Structure:","$"+chosenOption.pricePerLesson/100+"/hour"))
        step2.push(getStep2Row("Total:","$"+ priceShown))

        
        return step2
    }

    const getStep2Row = (title,value) => {
        let row = <div className='BPUstep2Row'>
            <div className='BPUstep2Dot' />
            <span className='BPUstep2Title'>{title}</span>
            <span>{value}</span>
        </div>
        return row
    }



    
    const inputUpdated = (inputArray) => {
        setInputArray([...inputArray])
        // console.log("BPU InUP", inputArray)
        

    }


    const checkBookingInputs = async () => {

        return new Promise(async (resolve, reject) => {

            let dataFilledError = checkInputsFilled()
            if(dataFilledError)
            {
                resolve(false)
            }else{
                let res = true//await checkTimesStillAvailable()
                console.log("RES CBI",res)
                resolve(res)
            }

        })
        
    }

    const checkInputsFilled = () => {
        var error = false
        inputArray.forEach(input => {
            if(input.date === undefined || input.time === undefined)
            {
                error = true
                input.error = true
            }
            else{
                console.log("IN HERE")
                var month = ((+input.date.getMonth())+1); if(month<10){month = "0"+month}
                var day = input.date.getDate(); if(day<10){day = "0"+day}
                let formattedDate = input.date.getFullYear() + "-" + month + "-"+ day
                let date = Date.parse(formattedDate + "T" + input.time)
                input.timestamp = date
            }
        });
        setInputArray([...inputArray])
        console.log("NEW INAR",inputArray)
        return error
    }


    const checkTimesStillAvailable = () =>{

        return new Promise(async (resolve, reject) => {
        

        inputArray.forEach(input => {
            console.log("DateTIme", input.date,input.time)

            var month = ((+input.date.getMonth())+1); if(month<10){month = "0"+month}
            var day = input.date.getDate(); if(day<10){day = "0"+day}

            let formattedDate = input.date.getFullYear() + "-" + month + "-"+ day
            console.log("FORMAT", formattedDate + "T" + input.time)
            let date = Date.parse(formattedDate + "T" + input.time)
            console.log("parse",date)

            input.timestamp = date
        });


        axios.post(baseURL+'confirmTutorStillAvailable',{
            tutorID:tutor.id,
            timezone: timeZone,
            lessons:inputArray
        }).then((response) => {
            let resVar = response.data
            console.log("Response", resVar)
            
            let chosenTimes = resVar.chosenTimes
            chosenTimes.forEach(element => {
                element.date = new Date(element.date)
            });
            console.log(inputArray,chosenTimes)
            if(!resVar.available){setInputArray([...chosenTimes])}
            resolve(resVar.available)
        })

        })

    }





    const pay = () =>  {

        var options = {
            key: "rzp_test_ZzCJNbW5649Mf4", // Enter the Key ID generated from the Dashboard
            amount: chosenOption.overallPrice, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
            currency: "INR",
            name: "Routeen",
            description: "Test Transaction",
            image: "https://example.com/your_logo",
            order_id: paymentOrder.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
            handler: function (response){
                // alert(response.razorpay_payment_id);
                // alert(response.razorpay_order_id);
                // alert(response.razorpay_signature)
                paymentSuccessful()
            },
            prefill: {
                name: "Caolan Melvin",
                email: "cm@example.com",
                contact: "9999999999"
            },
            notes: {
                address: "Razorpay Corporate Office"
            },
            theme: {
                color: "#3399cc"
            }
        };
        var rzp1 = new Razorpay(options);
        rzp1.on('payment.failed', function (response){
                alert(response.error.code);
                alert(response.error.description);
                alert(response.error.source);
                alert(response.error.step);
                alert(response.error.reason);
                alert(response.error.metadata.order_id);
                alert(response.error.metadata.payment_id);
        });
        rzp1.open()
    }

    const paymentSuccessful = () => {
        console.log("Successful Payment")
        const lessons = createLessonObjects(inputArray)
        
        let referralID = localStorage.getItem("referral")
        if(referralID !== null){logReferralPurchase(referralID,lessons)}

        localStorage.removeItem("referral")
        if(coupon!==null){
            couponUsed()
        }
        

        
        addLessonsLocally(lessons)
        addLessonsToDB(lessons)
        
    }

    const createLessonObjects = (inputs) => {

        const lessonArray = []
        let user = JSON.parse(localStorage.getItem("user"))

        

        inputs.forEach(input => {

            var isClass = false
            let lessonID = Math.random().toString(16).slice(2)

            groupClasses.forEach(groupClass => {
                if(groupClass.time === input.timestamp)
                {
                    isClass = true
                    lessonID = groupClass.id
                }
            });

            
            let lesson = {
                id:lessonID,
                uid:user.uid,
                tutorID:tutor.id,
                duration:chosenOption.duration,
                subject:chosenOption.name,
                studentName:user.name,
                tutorName:tutor.name,
                scheduled:true,
                startTime:new Date(input.timestamp),
                isClass:isClass
            }

            console.log("LESSON CLO",lesson)
            lessonArray.push(lesson)
        });

        for(var i = inputs.length;i<chosenOption.amountLesson;i++)
        {
            let lessonID = Math.random().toString(16).slice(2)
            let lesson = {
                id:lessonID,
                uid:user.uid,
                tutorID:tutor.id,
                duration:chosenOption.duration,
                subject:chosenOption.name,
                studentName:user.name,
                tutorName:tutor.name,
                scheduled:false,
            }
            lessonArray.push(lesson)
        }

        return lessonArray
    }

    const addLessonsToDB = (lessons) => 
    {
        axios.post(baseURL+'createLessons',{
            lessons:lessons,
            ppl:chosenOption.pricePerLesson,
            business:constants.BUSINESS
        }).then((response) => {
            let resVar = response.data
            console.log("Create Lessons", resVar)
            close(true,resVar.lessonIDs)
        })
    }

    const addLessonsLocally = (lessons) => {
        let lessonsLS = localStorage.getItem("lessons")
        var lessonArray = []
        if(lessonsLS !== null){lessonArray = JSON.parse(lessonsLS)}
        let newLessonAray = [...lessonArray,...lessons]
        localStorage.setItem("lessons",JSON.stringify(newLessonAray))


        let user = JSON.parse(localStorage.getItem("user"))
        lessons.forEach(lesson => {
            user.lessons.push(lesson.id)
        });
        localStorage.setItem("user",JSON.stringify(user))

    }

    
    const logReferralPurchase = (referralID,lessons) => 
    {
        axios.post(baseURL+'logReferralPurchase',{
            referralID:referralID,
            lessons:lessons,
            ppl:chosenOption.pricePerLesson,
            business:"Routeen"
        }).then((response) => {
            
        })
    }

    const searchForCoupon = (couponID) => {
        
        if(couponID === "")
        {
            setCouponError("Please enter value")
        }
        else{

            setCouponError("")
            let user = JSON.parse(localStorage.getItem("user"))
            let uid = user.uid

            axios.get(baseURL+'getCoupon',{
                params:{
                    couponID:couponID,
                    uid:uid,
                    business:"Routeen"
                }
            }).then((response) => {
                
                let resVar = response.data
                if(!resVar.error)
                {
                    let couponVar = resVar.coupon
                    

                    
                    if(couponVar.type ==="percentage")
                    {
                        setDiscountedPrice(chosenOption.overallPrice * ((100-couponVar.discount)/100))
                    }
                    else{
                        setDiscountedPrice(chosenOption.overallPrice - (couponVar.discount*100))
                    }

                    
                    setCoupon(couponVar)

                }
                else{
                    setCouponError(resVar.errorMessage)
                }
            })
      }
    }

    const couponUsed = () => {
        
        let user = JSON.parse(localStorage.getItem("user"))
        let uid = user.uid

        axios.get(baseURL+'couponUsed',{
            params:{
                couponID:couponID,
                uid:uid
            }
        }).then((response) => {


        })

    }

    const payClicked = () => {
        // if(addressText !== "")
        // {
        //     if(newCustomer)
        //     {
        //         addBillingAddress()
        //     }
        //     else{
        //         setCheckingPayment(true)
        //     }
        // }
        // else{

        // }

        setCheckingPayment(true)
        
    }



    return (
        <div className='popUpScreen'>

            
            <div className='bookingPopUp'>
                <div className='bookingPopUpTopRow'>
                    <span className='bookingPopUpTitle'>Booking Tutor</span>
                    <img className='bookingPopUpCancel' src='/assets/cross.png' onClick={closePopUp}/>
                </div>
                <div className='bookingPopUpLine' />

                <BookingStateRow step={step} clicked={stepClicked}/>
                <div className='bookingPopUpContent'>

                {step == 1 && 
                    <div className='bookingPopUp1'>
                        {getStep1()}
                    </div>
                }

                {step == 2 && 
                    <div className='bookingPopUp2'>
                        {getStep2()}
                        {coupon === null && <div className='bookingCouponSection'>
                            <span>Apply Referral Code (if any)</span>
                            <div className='bookingCouponRow'>
                                <input className='bookingCouponInput' type="text" placeholder="Coupon ID" value={couponID} onChange={(e) => setCouponID(e.target.value.replace("$",""))}/>
                                <button className='bookingCouponBtn' onClick={()=>searchForCoupon(couponID)}>Search</button>
                            </div>
                            <span>{couponError}</span>
                            
                        </div>}
                        {coupon !== null && <div className='bookingCouponInput applied'>
                            {coupon.name} Applied! - {coupon.description}
                        </div>}
                    </div>
                }


                {step == 3 &&
                    <div className='bookingPopUp3'>
                        <div className='bookingPopUpTutor'>
                            <img className='bookingPopUpImg' src={tutor.photos[0]}/>
                            <div className='bookingPopUpTutorColumn'>
                                <span className='bookingPopUpName'>{tutor.name}</span>
                                <span className='bookingPopUpJob'>{"Web Designer & Best-Selling Instructor"}</span>
                            </div>
                        </div>

                        <div className='bookingPopUpLine' />

                        {loadingAvailability && <span>Loading Availability</span>}
                        {(inputArray!== null && !loadingAvailability) && <BookingInputs max={chosenOption.amountLesson} inputArrayP={inputArray} inputsUpdated={inputUpdated} availability={availability} groupClasses={groupClasses}/>}
                    </div>
                }


                
                {(step == 4 && paymentLoading)&& <div>Loading Payment...</div>}
                
                {(step == 4 && !paymentLoading)&& 
                <div className='paymentSectionBPU'>
                    
                    {!showPaySuccess && <div className='paymentComponentBPU'>                        
                        {/* <button className='bookingPopUpPayBtn' onClick={pay}>Click to complete your payment via Routeen’s Secure Payment Partner</button> */}
                        {(setupIntentID) && 
                            
                            <Elements stripe={stripePromise} options={options} >
                                <PaymentComponent stripeResponse={stripeResponse}  submitClicked={checkingPayment} isSetup={false} savedCard ={{using:useSavedCard,secret:options.clientSecret}}/>
                            </Elements>
                        }
                        {(!setupIntentID) && 
                            <div>Loading Payment....</div>
                        }
                    </div>}

                    {showPaySuccess && <div className='successfulPayment'>
                        Payment Confirmed
                    </div>}

                </div>
                }
                </div>

                <div className='bookingPopUpBtns'>
                    <button className='bookingPopUpClose' onClick={closePopUp}>Close</button>
                    {(step<4 && step >1) && <button className='bookingPopUpContinue' onClick={nextStep}>Continue</button>}
                    {(step === 4) && <button className='bookingPopUpContinue' onClick={payClicked} disabled={!setupIntentID && !showPaySuccess}>Pay</button>}
                </div>

            </div>
            
        </div>
    )
}
