import AvailibityWindow from "./AvailablityWindow";
import React, { useEffect, useState } from 'react';

export default function AvailabiltyDay({index, tutor ,updated}) {

    
    const dayNames = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday']

    const [hoursRows,setHoursRows] = useState(tutor.availability[index].Available?tutor.availability[index].Times:[])
    const [updateVar,setUpdateVar] = useState(0)

    const [available,setAvailable] = useState(tutor!==null?tutor.availability[index].Available:false)
    

     
    useEffect(() => {
        
        updated(index,available,hoursRows,dayNames[index])
        
      }, [available,updateVar]);



    
    const showHourRows = () => {
        let hourArray = []
        var index = 0

        hoursRows.forEach(row => {
            let hourRow = 
                <AvailibityWindow 
                    index={index} 
                    startP={row.start} 
                    endP={row.end} 
                    edit={editHourRow}
                    deleteFunc={deleteHourRow}
                />
            hourArray.push(hourRow)
            index++
        });

        return hourArray
    }



    const addHourRow = () => {
        hoursRows.push({start:'09:00',end:'17:00'})
        setHoursRows(hoursRows)
        setAvailable(true)
        setUpdateVar(updateVar+1)
        
    }

    
    const editHourRow = (index,value) => {


        console.log("EHR",value)

        hoursRows[index] = value
        setHoursRows(hoursRows)
        setUpdateVar(updateVar+1)
        
        console.log("EHR",hoursRows)
    }

    const deleteHourRow = (index) => {
        hoursRows.splice(index, 1)
        setHoursRows(hoursRows)
        if(hoursRows.length === 0){setAvailable(false)}
        setUpdateVar(updateVar+1)

    }


    return(

        <div className='availabilityDay'>
            
            <span>{dayNames[index]}</span>

            {showHourRows()}
            
            <div className="addTimeSlotBtn" onClick={addHourRow}>Add Time Slot</div>

        </div>
    )
}