
import {useNavigate,useLocation,useParams} from 'react-router-dom';
import Dropdown from "../components/General/Dropdown";
import ReviewProfileTile from "../components/TutorProfile/ReviewProfile";
import TutorPriceCell from "../components/TutorProfile/TutorPricingCell";
import '../styles/tutor.css'
import React, { useEffect, useState } from 'react';
import ReviewInput from "../components/TutorProfile/ReviewInput";
import axios from "axios";
import { auth } from "..";
import * as constants from '../constants';

export default function TutorProfile({bookClicked}) {

    const baseURL = process.env.REACT_APP_BASEURL;

    const navigate = useNavigate();
    const location = useLocation()
    let { tutorID } = useParams();


    
    
    const [hasReviews,setHasReviews] = useState(false)
    const [tutor,setTutor] = useState(null)


    const [amountReviewsShown,setAmountReviewsShown] = useState(3)
    const [amountReviews,setAmountReviews] = useState([])
    const [reviews,setReviews] = useState([])
    const [filteredReviews,setFilteredReviews] = useState([])
    const [reviewsLoading,setReviewsLoading] = useState(false)

    const [options,setOptions] = useState([])
    const [optionsLoading,setOptionsLoading] = useState(false)
    const [experience,setExperience] = useState([])
    const [experienceLoading,setExperienceLoading] = useState(false)

    const [canReview,setCanReview] = useState(false)
    const [checkedReview,setCheckedReview] = useState(false)
    const [coupon, setCoupon] = useState(null)


    const reviewFilterOptions = [
        {id:5, name:"5 Star Rating"},
        {id:4, name:"4 Star Rating"},
        {id:3, name:"3 Star Rating"},
        {id:2, name:"2 Star Rating"},
        {id:1, name:"1 Star Rating"},
        
    ]

    
    // useEffect(() => {

    //     let couponLS = localStorage.getItem("coupon")
    //     console.log("COUPON LS",couponLS)
    //     if(couponLS !== null)
    //     {
    //         let couponJSON = JSON.parse(couponLS)
    //         setCoupon(couponJSON)
    //     }

    // },[])



    
// #region SHOW COMPONENTS
    const showMoreReviews = () => {
        let remaining = filteredReviews.length - amountReviewsShown
        if(remaining > 2){setAmountReviewsShown(amountReviewsShown+3)}
        else{setAmountReviewsShown(amountReviewsShown+remaining)}

        console.log(amountReviews,amountReviewsShown)
    }

    const getReviewList = () => {
        

        if(reviewsLoading){return <div>Loading Reviews...</div>}
        else{
            
            var reviewArray = []
            if(hasReviews)
            {
                for(var i=0;i<amountReviewsShown;i+=1)
                {
                    let review = filteredReviews[i]
                    reviewArray.push(<ReviewProfileTile review={review}/>)

                }
            }

            return reviewArray
        }

    }

    const getPriceOptions = () => {

        if(optionsLoading){return <div>Loading Options...</div>}
        else{

            var optionsArray = []
            options.forEach(option => {
                optionsArray.push(<TutorPriceCell bookClicked={() => bookClickedLocal(tutor,option,options,2)} priceOption={option} coupon={coupon}/>)
            });

            return optionsArray
        }
    }

    const reviewFilterChanged = (rating) => {
        let filtered = reviews.filter(function (review)
        {
            return review.rating === (+rating)
        })

        console.log("Filitered",filtered,rating)

        if(filtered.length<3){setAmountReviewsShown(filtered.length)}
        else{setAmountReviewsShown(3)}
        setFilteredReviews(filtered)
    }

    const showExperience = () => {

        if(experienceLoading){}
        else{
            var experienceArray = []
            experience.forEach(role => {

                let startDate = getWorkDate(role.startDate)
                var endDate = "Present"
                if(!role.present){endDate = getWorkDate(role.endDate)}

                experienceArray.push(
                    <div className="workHistoryTP">
                            <div className="workHistoryTProw">
                                <span className="workHistoryTPtitle">{role.title}</span>
                                <span className="workHistoryTPdate">{startDate} - {endDate}</span>
                            </div>
                            <span className="section3TPsubtext">{role.description}{role.description}</span>
                        </div>
                )
            });

            return experienceArray
        }
    }
// #endregion


// #region BUTTONS CLICKED
    const bookClickedLocal = (tutor,chosenOption, priceOptions,step) => {
        // const auth = getAuth();
        const user = auth.currentUser;
        if(user){
            bookClicked(tutor,chosenOption, priceOptions,step,coupon)
        }
        else{
            console.log("TUTOR",tutor)
            navigate("../auth", { replace: true, state:{tutor:tutor,chosenOption:chosenOption,priceOptions:priceOptions,step:step,coupon:coupon}});
        }

    }

    const addReview = (review) => {
        let newReviews = [review,...reviews]
        
        setReviews(newReviews)
        setAmountReviews(amountReviews+1)
        setHasReviews(true)

        let newFilteredReviews = [review,...filteredReviews]
        setFilteredReviews(newFilteredReviews)
        setAmountReviewsShown(amountReviewsShown+1)
    }

// #endregion


// #region WEB REQUESTS
    const loadTutorFromDB = () => {
        axios.get(baseURL+'getTutor',{
            params:{
                tutorID:tutorID,
            }
        }).then((response) => {
            let resVar = response.data
            setTutor(resVar)
            console.log("Tutor", resVar)
        })
    }

    const loadReviewsFromDB = () => {
        setReviewsLoading(true)
        axios.get(baseURL+'loadTutorReviews',{
            params:{
                tutorID:tutor.id,
            }
        }).then((response) => {
            let resVar = response.data
            if(resVar.length===0){
                setHasReviews(false)
            }
            setAmountReviews(resVar.length)
            setReviews(resVar)
            
            
            setFilteredReviews(resVar)
            setReviewsLoading(false)
            console.log("Review", resVar)
        })
    }

    
    const loadLessonOptionsFromDB = () => {
        setOptionsLoading(true)
        axios.get(baseURL+'loadTutorLessonOptions',{
            params:{
                tutorID:tutor.id,
            }
        }).then((response) => {
            let resVar = response.data
            setOptions(resVar)
            setOptionsLoading(false)
            console.log("Lessons", resVar)
        })
    }


    const loadExperienceFromDB = () => {
        setExperienceLoading(true)
        axios.get(baseURL+'loadTutorWorkExperience',{
            params:{
                tutorID:tutor.id,
            }
        }).then((response) => {
            let resVar = response.data
            setExperience(resVar)
            setExperienceLoading(false)
            console.log("Lessons", resVar)
        })
    }

// #endregion


// #region OTHER
const getWorkDate = (date) => {
    const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    let dateVar = new Date(date)
    let month = dateVar.getMonth()
    let year = dateVar.getFullYear()

    return (MONTHS[month] + " " + year)
}
// #endregion


    //Get tutor data from correct source
    if(tutor === null)
    {
        if(constants.MULTIPLE)
        {
            if(location.state !== null)
            {
                setTutor(location.state.tutor)    
            }
            else{
                loadTutorFromDB()
            }
        }
        else{

            let tutor = {
                id:constants.TUTOR_ID,
                bio:constants.BIO,
                languages:constants.LANGUAGES,
                subjects:constants.SUBJECTS,
                name:constants.NAME,
                photos:[constants.PHOTO],
                title:constants.TITLE,
                amountReviews:0,
                startingRate:constants.START_RATE,
                preference:constants.PREFERENCE,
                video:constants.VIDEO
            }

            setTutor(tutor)
            setOptions(constants.OPTIONS)

        }

        
    }




    useEffect(() => {

        if(tutor !== null)
        {
            
            loadReviewsFromDB()
            
            
            if(constants.MULTIPLE)
            {
                loadLessonOptionsFromDB()
                loadExperienceFromDB()
            }
            

            setAmountReviews(tutor.amountReviews)
        }

    },[tutor])

    


    if(location.state!==null)
    {
        if(location.state.chosenOption!==undefined)
        {
            console.log("STATE",location.state.chosenOption)
            bookClicked(location.state.tutor,location.state.chosenOption, location.state.priceOptions,location.state.step)
            location.state = null    
        }
    }

    if(localStorage.getItem("user") !== null && !checkedReview)
    {
        let user = JSON.parse(localStorage.getItem("user"))
        let previousTutors = user.previousTutors
        if( previousTutors !== undefined && previousTutors.includes(tutorID))
        {
            setCanReview(true)
            setCheckedReview(true)
        }
    }
    


    return(
        <div className="tutorScreen">

            {(tutor !== null && tutor !== undefined) && <div className="tutorScreenContainer">

                <div className="tutorMainSection">
                    <div className="section1TP">
                        <img className="section1TPimg" src={tutor.photos[0]}/>
                        <div className="section1TPInfo">

                            <div className="section1TPitleRow">
                                <span className="section1TPtitle">{tutor.name}</span>
                                <div className="section1TPbubble">
                                    <img className="section1TPbubbleIcon" src="/assets/greenmedal.png"/>
                                    <span>Top Rated</span>
                                </div>
                            </div>

                            <span className="section1TPsubtext">{tutor.title}</span>

                            <div className="section1TPratingrow">
                                <img className="fttStar" src="/assets/Star.png"/>
                                <span className="section1TPrating">{tutor.rating} ({tutor.amountReviews} reviews)</span>
                            </div>

                            <span className="section1TPprice">Starting From ${tutor.startingRate}/hr</span>

                            <button className="section1TPbtn" onClick={() => bookClickedLocal(tutor,null,options,1)} disabled={options===[]}>Book a class</button>
                        </div>
                    </div>


                    {/* {tutor.video !== undefined && <div className="section2TP">
                        <div className="titleRowTP">
                            <img className="titleIconTP" src="/assets/profile-circle.png"/>
                            <span className="titleTextTP">Sample Video</span>
                        </div>
                        <iframe
                            src={tutor.video}
                            frameborder="0"
                            allow="autoplay; encrypted-media;"
                            allowfullscreen="0"
                            title="video"
                            className="youtubeTP"
                        />

                    </div>} */}


                    <div className="tutorSideSectionMobile">
                        <span className="tutorPriceTitle">Pricing</span>
                        {getPriceOptions()}
                    </div>


                    <div className="section3TP">
                        <div className="titleRowTP">
                            <img className="titleIconTP" src="/assets/profile-circle.png"/>
                            <span className="titleTextTP">About {tutor.name}</span>
                        </div>
                        <span className="section3TPsubtext">{tutor.bio}</span>
                    </div>


                    <div className="section3TP">
                        <div className="titleRowTP">
                            <img className="titleIconTP" src="/assets/briefcase.png"/>
                            <span className="titleTextTP">Work History</span>
                        </div>
                        {showExperience()}
                        
                    </div>

                    <div className="section3TP">
                        <div className="titleRowTPreviews">
                            <span className="titleTextTP">Reviews ({tutor.amountReviews})</span>
                            <div className="divDropdown"><Dropdown data={reviewFilterOptions} onChange={(e) => reviewFilterChanged(e)}/></div>
                        </div>
                        
                        {getReviewList()}

                        {hasReviews && 
                            <div className="showMoreBtnTP">
                                {(filteredReviews.length>amountReviewsShown) &&<button className="section4DTbtn" onClick={showMoreReviews}>Show More</button>}
                            </div>
                        }

                        
                        {!hasReviews && <div className="noReviews">
                            <img className="noReviewsImg" src="/assets/noReviews.png"/>
                            <span className="noReviewsText">There are no reviews yet. Let’s give a review, your review is very helpful!</span>
                        </div>}

                        {canReview && <ReviewInput tutor={tutor} addReview={addReview}/> }

                    </div>

                    {/* <div>hello </div> */}


                </div>


                













                <div className="tutorSideSection">
                    
                    <div className="tutorPreferences preferences">
                        
                        <span className="tutorPrefTitle">Languages</span>
                        <span className="tutorPrefText">{tutor.languages.toString()}</span>

                        <span className="tutorPrefTitle">Expert on</span>
                        <span className="tutorPrefText">{tutor.subjects.join(", ")}</span>

                        {/* <span className="tutorPrefTitle">Social Media</span>
                        <div className="tutorSocialMediaRow">
                            {getSocialMedia()}
                        </div> */}

                        <span className="tutorPrefTitle">Student Profile</span>
                        <span className="tutorPrefText">{tutor.preference}</span>
                    </div>


                    <span className="tutorPriceTitle">Pricing</span>
                    {getPriceOptions()}
                </div>
                


            

            </div>}

        </div>
    )
}


