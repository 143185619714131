import React, { useEffect, useState } from 'react';
import '../../styles/home.css'
import '../../styles/tutor.css'


export default function ReviewProfileTile({review}) {

    const getTime = (reviewTime) => {

        let date = new Date()
        let now = date.getTime()

        let reviewDate = new Date(reviewTime)

        let differenceMinutes = (now-reviewDate.getTime())/60000

        let timeSince = getTimeString(differenceMinutes)

        return timeSince
    }

    const getTimeString = (minutes) => {

        if(minutes<30){return "Just Now"}
        else if(minutes < 120)
        {
            return Math.floor(minutes) + " minutes ago"
        }
        else if(minutes < 1440)
        {
            let hours = minutes/60
            return Math.floor(hours) + " hours ago"
        }
        else if(minutes< 10080){
            let days = minutes/1440
            return Math.floor(days) + " days ago"
        }
        else if(minutes<60480){
            let weeks = minutes/10080
            return Math.floor(weeks) + " weeks ago"
        }
        else if(minutes<1048320){
            let months = minutes/40320
            return Math.floor(months) + " months ago"
        }
        else{
            let years = minutes/524160
            return Math.floor(years) + " years ago"
        }
    }

    const getStars = () => {

        var stars = []
            
        for(var i=0;i<review.rating;i++)
        {
            stars.push(<img className='reviewStar' src='/assets/Star.png'/>)
        }

        return stars
    }

    return (
        <div className='reviewTileTP'>

            
            {/* <img className='reviewImage' src={review.studentImage}/> */}
            <span className="headerProfilePic">{review.studentName[0]}</span>
            <div className='reviewTileTPcolumn'>
                <div className='reviewTileTProw'>
                    <span className='reviewName'>{review.studentName}</span>
                    <span className='reviewTileTPtext'>• {getTime(review.timestamp)}</span>
                </div>
                <div className='reviewStars'>
                    {getStars()}
                </div>
                <span className='reviewTileTPtext'>{review.review}</span>

            </div>
            
        </div>
    )
}
