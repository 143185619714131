import { getAuth,signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import '../../../styles/dashboard.css'

export default function PracticeTestTile({type, clicked,obj,tutor}) {

    const [title,setTitle] = useState("")
    const [subtitle,setSubtitle] = useState("")
    const [buttonText,setButtonText] = useState("")

    useEffect(() => {
        if(type === "pack")
        {
            setTitle(obj.name)
            setButtonText("Start Practising")
            if(tutor){setButtonText("Edit Test")}
        }
        else if(type === "currentTest")
        {
            setTitle(obj.packName)
            let progress = Math.floor((obj.progress/obj.questions.length)*100)
            setSubtitle(progress + "% Completed")
            setButtonText("Continue Practising")
        }
        else if(type === "complete")
        {
            setTitle(obj.packName)
            setSubtitle("100% Completed")
            setButtonText("View Analytics")
        }
    },[])

    return(
        <div className="classTile">
            
            <div className={`classMainTile`}>
                <div className="classTileRow">
                    <div className="classTileInnerRow">
                        <div className="classTileColumn">
                            <span className="classTileTitle">{title}</span>
                            {type!=="pack" && <span className="classTileSubtitle">{subtitle}</span>}
                            {/* <span className="classTileSubtitle">{subSubtitle}</span> */}
                            
                        </div>
                    </div>
                </div>
               
            </div>
            <button className={`classTileBtn`} onClick={clicked}>{buttonText}</button>
        </div>
    )
}


