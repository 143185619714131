
import { useNavigate,useLocation } from "react-router-dom";
import Dropdown from "../components/General/Dropdown";
import FAQs from "../components/Discover/FAQs";
import React, { useEffect, useState } from 'react';
import axios from "axios";
import * as constants from '../constants';

import '../styles/discover.css'
import TeacherTile from "../components/Discover/TeacherTile";

export default function DiscoverTutors() {

    const location = useLocation()

    const baseURL = process.env.REACT_APP_BASEURL;


    const [lowPrice,setLowPrice] = useState("")
    const [highPrice,setHighPrice] = useState("")
    const [chosenSubject,setChosenSubject] = useState("")
    const [chosenSort,setChosenSort] = useState("")


    const sortOptions = [
        {id:"Price: Low to High", name:"Price: Low to High"},
        {id:"Price: High to Low", name:"Price: High to Low"},
        
    ]

    
    const lessonOptions = [
        {id:"English", name:"English"},
        {id:"Maths", name:"Maths"},
    ]


    const [loading,setLoading] = useState(false)

    const [tutors,setTutors] = useState([])
    const [tutorsShown,setTutorsShown] = useState(0)

    const [filteredTutors,setFilteredTutors] = useState([])


    useEffect(() => {
        if(location.state !== null)
        {
            let subject = location.state.subject
            console.log("Subject",subject)
            let subjectArray = []
            subjectArray.push(subject)
            loadTutorsFromDB(subjectArray)
        }
        else{loadTutorsFromDB([])}
    },[])



    
// #region SHOW COMPONENTS

    const getTutorList = () => {

        if(filteredTutors.length === 0)
        {
            return <div>Loading...</div>
        }
        else{
            var tutorsArr = []

            for(var i=0;i<tutorsShown;i++)
            {
                tutorsArr.push(<TeacherTile tutor={filteredTutors[i]} heart={true}/>)    
            }


            return tutorsArr
        }

        
    }

    const showMoreTutors = () => {

        var difference = filteredTutors.length-tutorsShown

        if(difference>8)
        {
            setTutorsShown(tutorsShown+8)
        }
        else{
            setTutorsShown(tutorsShown+difference)
        }

        
    }

    const applyFilters = () => {

        var filterArray = tutors

        console.log("Filter Array",filterArray)
        if(chosenSubject !== "")
        {
            filterArray = filterArray.filter(function (item)
            {
                return item.subjects.includes(chosenSubject)
            })
        }
        console.log("Filter Array",filterArray)

        if(lowPrice !== "")
        {
            filterArray = filterArray.filter(function (item)
            {
                return item.startingRate > lowPrice
            })
        }
        console.log("Filter Array",filterArray)

        if(highPrice !== "")
        {
            filterArray = filterArray.filter(function (item)
            {
                return item.startingRate < highPrice
            })
        }
        console.log("Filter Array",filterArray)

        if(filterArray.length <8){setTutorsShown(filterArray.length)}
        else{setTutorsShown(8)}
        setFilteredTutors(filterArray)
        setChosenSort("")
        
    }

    const clearFilters = () => {
        setLowPrice("")
        setHighPrice("")
        setChosenSubject("")
        setChosenSort("")
        
        if(tutors.length <8){setTutorsShown(tutors.length)}
        else{setTutorsShown(8)}
        setFilteredTutors(tutors)
    }

    const sortTutors = (val) => 
    {
        if(val === "Price: Low to High")
        {
            filteredTutors.sort((a, b) => parseFloat(a.startingRate) - parseFloat(b.startingRate));
        }
        else if(val === "Price: High to Low")
        {
            filteredTutors.sort((a, b) => parseFloat(b.startingRate) - parseFloat(a.startingRate));
        }
    }

// #endregion


// #region BUTTONS CLICKED
    const sortChanged = (val) => {
        setChosenSort(val)
        sortTutors(val)
        
    }


    const subjectChanged = (val) => {
        setChosenSubject(val)
    }


    const getConsultation = () => {
        const newWindow = window.open('https://calendly.com/raunaqbagga', '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }
// #endregion


// #region WEB REQUESTS
    const loadTutorsFromDB = (subjectArray) => {
        setLoading(true)
        axios.post(baseURL+'getTutors',{
            subjects:subjectArray,
            business:constants.BUSINESS
        }).then((response) => {
            let resVar = response.data
            setLoading(false)
            let tutors = response.data.tutor

            if(tutors.length <8){setTutorsShown(tutors.length)}
            else{setTutorsShown(8)}

            setTutors(tutors)
            setFilteredTutors(tutors)
            console.log("Response", resVar)
        })
    }
// #endregion


// #region OTHER

// #endregion


    


    

    return(
        <div className="homeScreen">

            <div className="homeScreenContainer">

                <div className="section1DTcontainer">
                    <div className="section1DT">

                    <img className="section1DTdots1" src="assets/dots1.png"/>
                    <div className="section1DTText">
                        <span className="section1DTTitle">Meet Our Tutors</span>
                        <span className="section1DTSubtext">All of our tutors are vetted for excellence in their work, so you can feel safe in your decision making</span>
                    </div>
                    <img className="section1DTdots2" src="assets/dots2.png"/>

                </div>
                </div>




                <div className="section3DT">
                    <div className="section3DTsort">
                        <span className="section3DTtitle">Sort by: </span>
                        <Dropdown data={sortOptions} onChange={sortChanged} value={chosenSort}/>
                    </div>
                    <div className="section3DTfilter">
                        <div className="section3DTleft">
                            <div className="section3DTcolumn">
                                <span className="section3DTtitle">Subject Taught</span>
                                <Dropdown data={lessonOptions} onChange={subjectChanged} value={chosenSubject}/>
                            </div>
                            
                        </div>
                        <div className="section3DTright">
                            <button className="section3DTclearBtn" onClick={clearFilters}>Clear all Filters</button>
                            <button className="section3DTapplyBtn" onClick={applyFilters}>Apply Filters</button>
                        </div>
                    </div>
                </div>


                <div className="section4DT">
                    <div className="section4DTgrid">
                        {getTutorList()}
                    </div>

                    {tutorsShown< filteredTutors.length && <button className="section4DTbtn" onClick={showMoreTutors}>View More</button>}
                </div>





                <div className="section2DT">

                    <div className="section2DTtile">
                        <div className="section2DTtitle">
                            <img className="section2DTIcon" src="assets/medal-star.png"/>
                            <span className="section2DTTitle">Only the very best</span>
                        </div>
                        <span className="section2DTSubText">Routeen admits only the finest of tutors so you can make your choice knowing that you will be in safe hands.</span>
                    </div>

                    <div className="section2DTtile">
                        <div className="section2DTtitle">
                            <img className="section2DTIcon" src="assets/cards.png"/>
                            <span className="section2DTTitle">Multiple Payment Options</span>
                        </div>
                        <span className="section2DTSubText">We understand how unique every student is and provide extremely flexible payment and scheduling options starting at a single class scheduled anytime, anywhere.</span>
                    </div>

                </div>



                <div className="section2HSRow2">
                    <div className="flexRow">
                        <img className="section2HSPhone" src="/assets/navyPhone.png"/>
                        <span className="section2HStxt">Help us select your ideal tutor, get a <strong>free</strong> 15 minute teacher discovery session</span>
                    </div>
                    <div className="flexRowBtns">
                        <button className="section2HSBtn" onClick={getConsultation}>Book Meeting</button>
                    </div>
                    
                </div>





                <div className="section5DT">

                    <div className="section5DTtext">
                        <span className="section5DTtitle">Got Questions?</span>
                        <span className="section5DTblue">We Have Answers.</span>
                        <span className="section5DTsubtext">If you can't find an answer to your question, kindly contact us at queries@routeen.in</span>
                    </div>

                    <FAQs />

                </div>


            

            </div>

        </div>
    )
}


