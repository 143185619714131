import React, { useEffect, useRef, useState } from 'react';
import '../../styles/booking.css'

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { auth } from '../..';
import * as constants from '../../constants';

export default function SchedulerPopUp({lesson,closePopUp,lessonScheduled}) {

    const baseURL = process.env.REACT_APP_BASEURL;
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)
    const [selectedTime, setSelectedTime] = useState("")
    const [startDate, setStartDate] = useState(new Date());
    const [sortOptions, setSortOptions] = useState([])
    const [timeSlots, setTimeSlots] = useState(null)
    const [groupClasses, setGroupClasses] = useState([])


// #region SHOW COMPONENTS
const calculateTimeSlots = (dateChangePrompt,timeSlots,date) =>
    {
        if(dateChangePrompt){setSelectedTime("")}
        
        let timeOptions = []
        let timestamps = timeSlots[date]
        if(timestamps !== undefined)
        {
            timestamps.forEach(timestamp => {
                let classCheck = checkIfClass(timestamp,groupClasses)

                let date = new Date(timestamp)
                var hours = date.getHours(); if(hours<10){hours="0"+hours}
                var minutes = date.getMinutes(); if(minutes<10){minutes="0"+minutes}
                if(classCheck.isClass){timeOptions.push({id:hours+":"+minutes,name:hours+":"+minutes + " - " + classCheck.name})}
                else{timeOptions.push({id:hours+":"+minutes,name:hours+":"+minutes})}
            });
        }
        

        setSortOptions(timeOptions)
    }

// #endregion

// #region BUTTONS CLICKED

    const optionChanged = async(event) => {

        setSelectedTime(event.target.value)

    }

    const dateChanged = (date) => {


        setStartDate(date)

        let formattedDate = date.getDate() + "-" + ((+date.getMonth())+1) + "-"+ date.getFullYear()
        calculateTimeSlots(true,timeSlots,formattedDate)
        
    }

// #endregion

// #region WEB REQUESTS
    const loadAvailabilityFromDB = () => {

        setLoading(true)
        axios.get(baseURL+'getTutorAvailability',{
            params:{
                tutorID:lesson.tutorID,
                timezone: timeZone
            }
        }).then((response) => {
            setLoading(false)
            let availability = response.data.times
            setGroupClasses(response.data.classes)
            setTimeSlots(availability)
            let formattedDate = startDate.getDate() + "-" + ((+startDate.getMonth())+1) + "-"+ startDate.getFullYear()
            calculateTimeSlots(true,availability,formattedDate)
        })
    }

    const confirmStillAvailable = () => {

        var month = ((+startDate.getMonth())+1); if(month<10){month = "0"+month}
        var day = startDate.getDate(); if(day<10){day = "0"+day}

        let formattedDate = startDate.getFullYear() + "-" + month + "-"+ day
        let timestamp = Date.parse(formattedDate + "T" + selectedTime)
        
        
        axios.post(baseURL+'confirmTutorStillAvailable',{
            tutorID:lesson.tutorID,
            timezone: timeZone,
            lessons:[{key:0, date:startDate,time:selectedTime,error:false,timestamp:timestamp}]
        }).then((response) => {
            let resVar = response.data
            console.log("RESVAR",resVar)
            if(resVar.available){
                scheduleLesson()
            }
            else{
                setError(true)
            }
            
        })

    }

    
    const scheduleLesson = () => {

        var month = ((+startDate.getMonth())+1); if(month<10){month = "0"+month}
        var day = startDate.getDate(); if(day<10){day = "0"+day}
        let formattedDate = startDate.getFullYear() + "-" + month + "-"+ day
        let dateTimeStr = formattedDate + "T" + selectedTime
        let timestamp = Date.parse(dateTimeStr)

        lessonScheduled(lesson.id,dateTimeStr)

        var isClass = false
        var classID = ""
        groupClasses.forEach(groupClass => {
            if(groupClass.time === timestamp)
            {
                isClass = true
                classID = groupClass.id
            }
        });

        let user = JSON.parse(localStorage.getItem("user"))


        axios.post(baseURL+'scheduleLesson',{
            uid:auth.currentUser.uid,
            studentName:user.name,
            lessonID:lesson.id,
            time:timestamp,
            isClass:isClass,
            classID:classID
        }).then((response) => {
            let resVar = response.data
            console.log("RESVAR S",resVar)
            
        })


        axios.get(baseURL+'classSheduledCredit',{
            params:{
                tutor: constants.TUTOR_ID,
                amountClasses: 1
            }
        }).then( (response) => {
        
            
        });

    }



// #endregion

    
// #region OTHER
const getTimeZone = () => {
    const offset = (new Date()).getTimezoneOffset();

    let hours = offset/-60

    if(hours < 0){return "GMT"+hours}
    else if(hours > 0){return "GMT+"+hours}
    else{return "GMT"}

}

const checkIfClass = (timestamp,groupClasses) => 
    {
        var isClass = false
        var name = ""

        groupClasses.forEach(groupClass => {
            if(groupClass.time === timestamp)
            {
                isClass = true
                name = groupClass.name
            }
        });

        return{isClass:isClass,name:name}
    }

// #endregion



    
    
    if(timeSlots === null && !loading)
    {
        loadAvailabilityFromDB(lesson.tutorID)
    }



    
    



    


    return (
        <div className='schedulerShadow'>
            <div className='schedulerPopUp'>

                <div className='schedulerRightAlign'><img className='bookingPopUpCancel' src='/assets/cross.png' onClick={closePopUp}/></div>
                <span>{lesson.subject} - {lesson.tutorName}</span>
                {/* <span>Tutor</span> */}

                {loading && <div>Loading</div>}
                
                {!loading && 
                    <div className='bookingInput'>
                        <div className='bookingColumn'>
                            <span>Date</span>
                            <div className={`bookingDropdown ${error?"error":""}`}>
                                <DatePicker className='datePicker' selected={startDate} onChange={(date) => dateChanged(date)} dateFormat="dd-MM-yyyy"/>
                            </div>
                            
            
                        </div>
                        <div className='bookingColumn'>
                            <div className='bookingTitleRow'>
                                <span>Start Time</span>
                                <span className="timezoneSpan">(Time is in {getTimeZone()})</span>
                            </div>
                            <select className={`bookingDropdown ${error?"error":""}`} onChange={optionChanged} placeholder='Choose Court' value={selectedTime}>
                                <option value="" disabled selected>Select your option</option>
                                {sortOptions.map((option) => (
                                    <option value={option.id}>{option.name}</option>
                                ))}
                            </select>
                        </div>
                        
                        
                    </div>
                }
                <div className='schedulerRightAlign'><button className='bookingPopUpContinue' onClick={confirmStillAvailable}>Continue</button></div>
            </div>
        </div>
    )
}