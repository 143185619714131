import React, { useEffect, useState } from 'react';
import '../../styles/home.css'
import '../../styles/tutor.css'
import axios from "axios";


export default function ReviewInput({tutor, addReview}) {

    const baseURL = process.env.REACT_APP_BASEURL;

    const [reviewText,setReviewText] = useState("")
    const [starsHighlighted,setStarsHighlighted] = useState(0)
    const [starsSelected,setStarsSelected] = useState(0)

    const [submitted,setSubmitted] = useState(false)

    const reviewSubmitted = () => {

        let user = JSON.parse(localStorage.getItem("user"))
        let uid = user.uid
        let name = user.name

        setSubmitted(true)

        addReviewToDB(uid,name)
        
        let review = {
            rating:starsSelected,
            studentName:name,
            studentImage:'https://c8.alamy.com/comp/W35TDF/cologne-germany-12th-july-2019-the-actor-peter-kurth-stands-in-the-studio-after-the-recording-of-the-wdr-talk-show-klner-treff-credit-henning-kaiserdpaalamy-live-news-W35TDF.jpg',
            review:reviewText,
            timestamp: new Date()
        }

        addReview(review)
    }

    const addReviewToDB = (uid,name) => {
        axios.post(baseURL+'addReview',{
            rating:starsSelected,
            uid:uid,
            tutorID:tutor.id,
            userName:name,
            image:'https://c8.alamy.com/comp/W35TDF/cologne-germany-12th-july-2019-the-actor-peter-kurth-stands-in-the-studio-after-the-recording-of-the-wdr-talk-show-klner-treff-credit-henning-kaiserdpaalamy-live-news-W35TDF.jpg',
            review:reviewText
        }).then((response) => {
            let resVar = response.data
            
            console.log("Review", resVar)
        })
    }

    const starClicked = (index) =>
    {
        setStarsSelected(index+1)
    }

    const starHovered = (index) => {
        setStarsHighlighted(index+1)
    }

    const getStars = () => {

        var stars = []

        var index = 0;
        while(index<starsHighlighted)
        {
            const thisIndex = index
            const currentlySet = starsSelected
            stars.push(<img className='reviewStar reviewInput' src='/assets/Star.png' onClick={()=>starClicked(thisIndex)} 
            onMouseEnter={()=>starHovered(thisIndex)} 
            onMouseLeave={()=>starHovered(starsSelected-1)}/>)
            index++
        }

        while(index<5)
        {
            const thisIndex = index
            const currentlySet = starsSelected
            stars.push(<img className='reviewStar reviewInput' src='/assets/emptyStar.png' onClick={()=>starClicked(thisIndex)}
            onMouseEnter={()=>starHovered(thisIndex)} 
            onMouseLeave={()=>starHovered(starsSelected-1)}/>)
            index++
        }        

        return stars
    }


    return (
        <div className="addReviewTP">
            <span>Add your review</span>
            <div className="titleRowTPreviews">
                <span>Give rating</span>
                <div className='reviewStars'>
                    {getStars()}
                </div>
            </div>
            {!submitted && <textarea className="addReviewTPinput" type='text' placeholder="Share your review details" value={reviewText} onChange={(e) => setReviewText(e.target.value)}/>}
            {submitted && <div className='addReviewTPsubmitted'>
                <img className="contactSubmittedImg" src="/assets/submittedTick.png"/>
                <span>Review Subtmitted, Thank you!</span>
            </div>}
            <div className="addReviewTPbtnDiv">
                <button className="addReviewTPbtn" onClick={reviewSubmitted} disabled={submitted}>Submit</button>
            </div>
        </div>
    )
}
