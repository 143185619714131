import React, { useEffect, useRef, useState } from 'react';
import '../../styles/booking.css'
import BookingInput from './BookingInput';


export default function BookingInputs({max,inputArrayP,inputsUpdated,availability,groupClasses}) {


    const [inputArray, setInputArray] = useState(inputArrayP)
    const [amountInputs,setAmountInputs] = useState(1)
    const [currentAvailability,setCurrentAvailability] = useState(availability)
    const [updateIndex,setUpdateIndex] = useState(0)

    const [inputChangedIndex,setInputChagedIndex] = useState(-1)
    const [changeMade,setChangedMade] = useState(null)
    const myRef = useRef();


    // useEffect(() => {
    //     console.log("EFF InUP", inputArray)
    //     inputsUpdated(inputArray)
    // },[inputArray.length])

    console.log("HEARD FROM HIM", groupClasses)
    
    
    useEffect(() => {
        // console.log("Update array",inputArrayP)
        setInputArray(inputArrayP)
    },[inputArrayP])


    useEffect(() => {
        setCurrentAvailability(availability)
    },[availability])


    
    useEffect(() => {
        console.log("BI AV UP ", availability)
    },[currentAvailability,updateIndex])

    const addInput = () => {
        let key = (Math.random() + 1).toString(36).substring(7);
        let localArray = inputArray
        localArray.push({key:key, date:undefined,time:undefined, error:false})
        setInputArray(localArray)
        setAmountInputs(localArray.length)      
        console.log("ADD",localArray,inputArray)
    }
    
    const removeInput = (key) => {

        console.log("key delete",key)

        let localArray = inputArray.filter(function (item)
        {
            return item.key !== key
        })

        setInputArray([])
        setInputArray(localArray)  
        setAmountInputs(localArray.length)      
        inputsUpdated(localArray)
        console.log("REMOVE",localArray,inputArray)
    }

    const setValue = (index,value) => {
        let localArray = inputArray
        localArray[index] = value
        setInputArray(localArray)
        inputsUpdated(localArray)
        // console.log("Update",localArray,inputArray)
    }

    

    const getInputs = () =>
    {
        var inputs = []
        inputs.push(<BookingInput value={inputArray[0]} setValue={setValue} index ={0} availability={availability} updateAvailability={updateAvailability} updateIndex={updateIndex} changedIndex={inputChangedIndex} changeMade={changeMade} ref={myRef} groupClasses={groupClasses}/>)
        // console.log("input array length", inputArray.length)
        for(var i=1;i<inputArray.length;i++)
        {
            // console.log("in loop", inputArray[i])
            inputs.push(<BookingInput showBin={true} deleteInput={removeInput} value={inputArray[i]} setValue={setValue} index ={i} availability={availability} updateAvailability={updateAvailability} updateIndex={updateIndex} changedIndex={inputChangedIndex} changeMade={changeMade} ref={myRef} groupClasses={groupClasses}/>)
        }

        return inputs
    }


    
    const updateAvailability = (date,time,chosen,index) => {
        console.log("UPDATE RETYUIO",chosen, updateIndex)
        setChangedMade({date:date,time:time,chosen:chosen})
        setInputChagedIndex(index)
        if(chosen){setUpdateIndex(updateIndex+1)}
        else{setUpdateIndex(updateIndex-1)}

    }

    
    
    return (
        <div className='bookingInputs'>
            {getInputs()}
            {(inputArray.length<max) &&
                <div className='bookingAddClass'>
                    <span onClick={addInput}>Add Next Class</span>
                    <img className='bookingAddClassIcon' src='/assets/addClass.png' onClick={addInput}/>
                </div>
            }
        </div>
    )
}
