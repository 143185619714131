import { getAuth,signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import '../../../styles/dashboard.css'
import axios from "axios";


export default function ClassTile({classObj, buttonClicked,isTutor,cancelled}) {

    const baseURL = process.env.REACT_APP_BASEURL;

    const [date,setDate] = useState("")
    const [time,setTime] = useState("")
    const [title,setTitle] = useState("Maths")
    const [subtitle,setSubtitle] = useState("")
    const [subSubtitle,setSubSubtitle] = useState("")
    const [colour,setColour] = useState("blue")
    const [icon,setIcon] = useState("assets/classMaths.png")
    const [isClass,setIsClass] = useState(true)
    const [isScheduled,setIsScheduled] = useState(classObj.scheduled)
    const [buttonText,setButtonText] = useState("Go To Class")

    const [showTitle,setShowTitle] = useState(true)
    const [showButton,setShowButton] = useState(true)

    const [showCancel,setShowCancel] = useState(false)

    useEffect(() => {

        if(classObj.type === "class" || classObj.type === undefined)
        {
            setIsScheduled(classObj.scheduled)
            if(classObj.subject !== "Maths"){
                setTitle(classObj.subject)
                setColour("green")
                setIcon("assets/classEnglish.png")
            }
            setSubtitle(classObj.tutorName)
            if(isTutor){setSubtitle(classObj.studentName)}


            if(!classObj.scheduled){setButtonText("Schedule Now")}
            else{
                getTimeStr()
            }
        }
        else{
            setColour("green")
            setIsClass(false)
            setIcon("assets/upcomingPayment.png")
            setTitle(classObj.price)
            setSubtitle(classObj.amount)
            setSubSubtitle(classObj.person)
            setShowTitle(false)
            setShowButton(false)

            if(classObj.type == "paymentTotal")
            {
                setSubtitle(classObj.month)
                setButtonText("View More")
                setShowButton(true)
            }

            if(classObj.type == "paymentFinal")
            {
                setButtonText("Total: " + classObj.total)
                setShowButton(true)
            }
            
            
        }

        console.log(colour)
        console.log(colour === "green")
      
    },[])

    const calculateDateTime = (time) => {
        
        console.log("TIME ",time )
        const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        
        let dateVar = new Date(time)

        let day = dateVar.getDate()
        let month = dateVar.getMonth()

        let year = dateVar.getFullYear()
        setDate(day+" "+MONTHS[month]+" "+year)

        //-------------------------------------
        var hours = dateVar.getHours()
        var minutes = dateVar.getMinutes()
        if(minutes<10){minutes="0"+minutes}

        var postfix = "am"
        if(hours > 12){
            hours = hours-12
            postfix = "pm"
        }
        if(hours === 12){postfix="pm"}

        return(hours+":"+minutes+postfix)
    }

    const getTimeStr = () => {

        let startTime = calculateDateTime(classObj.startTime)
        let endTime = calculateDateTime(new Date(classObj.startTime).getTime() + (60000*classObj.duration))

        setTime(startTime + " - " + endTime)
    }

    const cancelLessonClicked = () => {
        console.log("Cancel Lesson!")
        setShowCancel(true)
    }

    const cancelConfirmed = () => {
        axios.post(baseURL+'cancelLesson',{
            id:classObj.id
        }).then((response) => {
            let resVar = response.data
            
            console.log("DELETED response",resVar)
            cancelled(classObj.id)
            setShowCancel(false)
        })
    }

    return(
        <div className="classTile">
            {showTitle && <span className={`classTitle ${isScheduled?"":colour}`}>{date}</span>}
            <div className={`classMainTile ${colour==="green"?"green":""}`}>
                <div className="classTileRow">
                    <div className="classTileInnerRow">
                        <img className="classTileIcon" src={icon}/>
                        <div className="classTileColumn">
                            <span className="classTileTitle">{title}</span>
                            <span className="classTileSubtitle">{subtitle}</span>
                            <span className="classTileSubtitle">{subSubtitle}</span>
                            {/* {classObj.type != "class" && <span className="classTileSubtitle">{classObj.tutorName}</span>} */}
                        </div>
                    </div>
                    {classObj.type == 'class' && <div className="classDots">
                        <div className={`classDot ${colour=='green'?"green":""}`} />
                        <div className={`classDot ${colour=='green'?"green":""}`} />
                    </div>}
                </div>
                {(isScheduled && isClass) &&<div className="classTileTime">
                    <img className="classTimeIcon" src={colour==="green"?"assets/classClockGreen.png":"assets/classClock.png"}/>
                    <span className="classTimeText">{time}</span>
                </div>}
            </div>
            {showButton && <button className={`classTileBtn ${colour=='green'?"green":""}`} onClick={buttonClicked}>{buttonText}</button>}
            {(isScheduled && isClass && isTutor) && <button className={`classTileBtn ${colour=='green'?"green":""}`} onClick={cancelLessonClicked}>{"Cancel Lesson"}</button>}

            {showCancel && <div className="cancelLessonConfirmation">
                <div className="cancelLessonPopUp">
                    Are you sure you want to cancel this class?
                    <div className="cancelLessonPopUpRow">
                        <button className="section1HSBtn startLearning"  onClick={()=>setShowCancel(false)}>No</button>
                        <button className="section1HSBtn discoverTutors"  onClick={cancelConfirmed}>Yes Please</button>
                    </div>
                    
                </div>
            </div>}
        </div>
    )
}


