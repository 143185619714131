import React, { useEffect, useState } from 'react';


export default function Dropdown({data,onChange,value}) {


    const [selectedValue, setSelectedValue] = useState(value)
    
    useEffect(() => {
        console.log("HELLO VALUE",value)
        setSelectedValue(value)
    },[value])

    const optionChanged = (event) => {

        setSelectedValue(event.target.value)
        onChange(event.target.value)
    }

    return (
        <div>

        <select className='dropdown' onChange={optionChanged} placeholder='Choose Court' value={selectedValue}>
            <option value="" disabled selected>Select your option</option>
            {data.map((option) => (
                <option value={option.id}>{option.name}</option>
            ))}
        </select>
            
            
            
        </div>
    )
}
