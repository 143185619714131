import './App.css';
import * as constants from './constants'

import {BrowserRouter as Router, Route,Routes,Navigate} from 'react-router-dom'
import React, { useEffect, useState } from 'react';
import { getAuth,onAuthStateChanged} from "firebase/auth";
import ContactUs from './screens/ContactUs';
import { auth } from '.';
import axios from "axios";
import Header from './components/Header';
import Home from './screens/Home';
import LoginRegister from './screens/LoginRegister';
import DiscoverTutors from './screens/DiscoverTutors';
import TutorProfile from './screens/TutorProfile';
import BookingPopUp from './components/BookingPopUp/BookingPopUp';
import Dashboard from './screens/Dashboard';
import BuyClasses from './screens/BuyClasses';

function App() {

  const baseURL = process.env.REACT_APP_BASEURL;

  const [onSignIn, setOnSignIn] = useState(false)
  const [loggedIn, setLoggedIn] = useState(false)
  const [userData, setUserData] = useState(null)

  const [selectedRoute, setSelectedRoute] = useState('home')

  const [tutor, setTutor] = useState(null)
  const [chosenOption, setChosenOption] = useState(null)
  const [priceOptions, setPriceOptions] = useState(null)
  const [showPopUp, setShowPop] = useState(false)
  const [popUpStep, setPopUpStep] = useState(null)
  const [lessonIDs,setLessonIDs] = useState([])


  useEffect(() => {

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log("Auth change", true,user)
        setLoggedIn(true)
        loadUserFromDB(user.uid)

      } else {
        console.log("Auth change", false,)
        setLoggedIn(false)
      
      }
    })
    return () => unsubscribe()
  
  },[])


  const routeChange = (route) => {
    setSelectedRoute(route)
    setOnSignIn(route==='auth')
  }

  
  const openPopUp = (tutor,chosenOption, priceOptions,step,coupon) => {

    console.log("Chosen option",chosenOption)

    window.scrollTo(0, 0)
    document.body.style.overflow = "hidden"
    setTutor(tutor)
    setChosenOption(chosenOption)
    setPriceOptions(priceOptions)
    setPopUpStep(step)
    setShowPop(true)
  }


  
  const closePopUp = (finished,lessons) => {
    document.body.style.overflow = "auto"
    setShowPop(false)
    if(finished){
      console.log("finished")
      setLessonIDs(lessons)
    }
  }


//----------------------------------------------------------------------------
// BACKEND CALLS


  
  const loadUserFromDB = (uid) => {

    return new Promise(async (resolve, reject) => {

    axios.get(baseURL+'getAccountDetails',{
        params:{
            uid:uid,
            business:constants.BUSINESS
        }
    }).then((response) => {
      if(!response.data.error)
        {
          let userAccountData = response.data.user
          localStorage.setItem("user",JSON.stringify(userAccountData))
          localStorage.setItem("creditBalance",response.data.creditBalance)

          setUserData(userAccountData)
          loadLessonsFromDB(userAccountData.uid,userAccountData.isTutor)
          resolve()
        }
        else{
          let user_data = JSON.parse(localStorage.getItem("user"))
          setUserData(user_data)
          resolve()
        }
        
    })

  })

  }

  
  
  const loadLessonsFromDB = (uid,isTutor) => {


    axios.post(baseURL+'getLessons',{
        uid:uid,
        isTutor:isTutor,
        business:constants.BUSINESS,
    }).then((response) => {
        let resVar = response.data
        console.log("Lessons", resVar)
        localStorage.setItem("lessons",JSON.stringify(resVar))
        
    })

}



const getHeader = () => {
  if (window.location.pathname === '/auth') return null;
    return <Header selectedRouteG={selectedRoute} loggedIn={loggedIn} routeChanged={routeChange} user={userData}/>
}



//----------------------------------------------------------------------------



  return (
    <div className="App">

    <Router className="Router">

      {getHeader()}

      <Routes>
        {/* <Route path='/'  element={<Home/>} /> */}
        <Route path='/auth'  element={loggedIn?<Navigate to="/"/>:<LoginRegister/>} />
        <Route path='/contact'  element={<ContactUs/>} />
        <Route path='/dashboard'  element={<Dashboard routeChanged={routeChange}/>} />
        <Route path='/discover'  element={<DiscoverTutors />} />
        <Route path='/tutor/:tutorID'  element={constants.MULTIPLE?<TutorProfile bookClicked = {openPopUp}/>:<Navigate to="/tutor"/>} />
        <Route path='/' element={constants.MULTIPLE?<Navigate to="/tutor"/>:<TutorProfile bookClicked = {openPopUp}/>} />
        <Route path='/buy'  element={<BuyClasses />} />

      </Routes>



    </Router>

    {showPopUp && <BookingPopUp close={closePopUp} tutor={tutor} priceOptions={priceOptions} chosenOptionP={chosenOption} stepP={popUpStep} couponP={null}/>}


    </div>
  );
}

export default App;
