import React, { useEffect, useState } from 'react';
import '../../styles/booking.css'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import moment from 'moment';

export default function BookingInput({showBin,deleteInput,value,setValue,index,availability,updateAvailability,updateIndex,changedIndex,changeMade,groupClasses}) {

    

    const [timeSlots, setTimeSlots] = useState(availability)

    const [bookingObj, setBookingObj] = useState(value)
    const [selectedTime, setSelectedTime] = useState(value.time || "")
    const [startDate, setStartDate] = useState(value.date || new Date());


    const [sortOptions, setSortOptions] = useState([])

    const [timeSlotSelected, setTimeSlotSelected] = useState(false)

    const [specificAvailability, setSpecificAvailability] = useState(null)

    if(bookingObj.date === undefined)
    {
        bookingObj.date = new Date()
        setBookingObj(bookingObj)

        console.log("VALSD",bookingObj)
    }

    useEffect(() => {

    
        setTimeSlots(availability)
        if(startDate !== "" && availability !== null){
            let formattedDate = startDate.getDate() + "-" + ((+startDate.getMonth())+1) + "-"+ startDate.getFullYear()
            calculateTimeSlots(true,availability,formattedDate)
        }
    

    },[availability])


    useEffect(() => {
        // console.log("Upadate",value)
        setBookingObj(value)
    },[value])

    
    useEffect(() => {
        console.log("CHANGE MADE",index,changeMade)
        if(changedIndex !== index && changeMade !== null)
        {
            console.log("ENFORCING CHANGE",index,changeMade)
            if(changeMade.chosen){
                removeTimeFromAvailability(changeMade.date,changeMade.time)
            }
            else{
                addTimeToAvailability(changeMade.date,changeMade.time)
            }
        }
    },[updateIndex])

 
    const delay = ms => new Promise(res => setTimeout(res, ms));


    const optionChanged = async(event) => {

        if(timeSlotSelected){
            changeAvailableTimes(startDate,selectedTime,false)
            console.log("ADDING BACL")            
        }

        setSelectedTime(event.target.value)
        bookingObj.time = event.target.value
        setBookingObj(bookingObj)
        setValue(bookingObj,index)
        
        bookingObj.error = false
        setBookingObj(bookingObj)
        setTimeSlotSelected(true)

        await delay(500);
        changeAvailableTimes(startDate,event.target.value,true)
    }
    
    const dateChanged = (date) => {

        if(timeSlotSelected){changeAvailableTimes(startDate,selectedTime,false)}
        setTimeSlotSelected(false)

        setStartDate(date)
        console.log("DATE",date)
        
        bookingObj.date = date
        setBookingObj(bookingObj)
        setValue(bookingObj,index)

        let formattedDate = date.getDate() + "-" + ((+date.getMonth())+1) + "-"+ date.getFullYear()
        calculateTimeSlots(true,timeSlots,formattedDate)

        bookingObj.error = false
        setBookingObj(bookingObj)

        
    }


    const calculateTimeSlots = (dateChangePrompt,timeSlots,date) =>
    {
        if(dateChangePrompt){setSelectedTime("")}
        
        let timeOptions = []
        let timestamps = timeSlots[date]
        if(timestamps !== undefined)
        {
            timestamps.forEach(timestamp => {

                let classCheck = checkIfClass(timestamp,groupClasses)

                let date = new Date(timestamp)
                var hours = date.getHours(); if(hours<10){hours="0"+hours}
                var minutes = date.getMinutes(); if(minutes<10){minutes="0"+minutes}
                if(classCheck.isClass){timeOptions.push({id:hours+":"+minutes,name:hours+":"+minutes + " - " + classCheck.name})}
                else{timeOptions.push({id:hours+":"+minutes,name:hours+":"+minutes})}
            });
        }
        

        setSortOptions(timeOptions)
    }


    const changeAvailableTimes = (dateP,timeP,choseTime) => {

        
        var month = ((dateP.getMonth())+1); 
        var day = dateP.getDate(); 
        let date = day+"-"+month+"-" +dateP.getFullYear()

        if(month<10){month = "0"+month}
        if(day<10){day = "0"+day}

        let formattedDate = dateP.getFullYear() + "-" + month + "-"+ day
        let time = Date.parse(formattedDate + "T" + timeP)

        updateAvailability(date,time,choseTime,index)
    
    }


    const removeTimeFromAvailability = (date,time) => {
        var newAv
        if(specificAvailability ===null){ newAv = Object.assign({}, availability);}
        else{newAv = Object.assign({}, specificAvailability);}
        let day = newAv[date]
        day = day.filter(item => item !== time)
        newAv[date] = day

        setSpecificAvailability(newAv)

        setTimeSlots(newAv)
        if(startDate !== "" && newAv !== null){
            let formattedDate = startDate.getDate() + "-" + ((+startDate.getMonth())+1) + "-"+ startDate.getFullYear()
            calculateTimeSlots(false,newAv,formattedDate)
        }

        if(timeSlotSelected){console.log("TIME SELECTED",selectedTime)}
        
    }

    const addTimeToAvailability = (date,time) => {
        var newAv
        if(specificAvailability ===null){ newAv = Object.assign({}, availability);}
        else{newAv = Object.assign({}, specificAvailability);}

        let day = newAv[date]
        
        var index = 0; var found = false
        while(index<day.length && !found){
            let timestamp = day[index]
            if(timestamp > time){found = true}
            index++
        }

        if(found){day.splice(index-1,0,time)}
        else{day.push(time)}

        setSpecificAvailability(newAv)

        setTimeSlots(newAv)
        if(startDate !== "" && newAv !== null){
            let formattedDate = startDate.getDate() + "-" + ((+startDate.getMonth())+1) + "-"+ startDate.getFullYear()
            calculateTimeSlots(false,newAv,formattedDate)
        }

    }

    const deleteClicked = () => {
        deleteInput(value.key)
        changeAvailableTimes(startDate,selectedTime,false)
    }


    useEffect(() => {

        setBookingObj(value)
        setSelectedTime(value.time || "")
        setStartDate(value.date || new Date())

    },[value,index])
    
    const getTimeZone = () => {
        const offset = (new Date()).getTimezoneOffset();

        let hours = offset/-60

        if(hours < 0){return "GMT"+hours}
        else if(hours > 0){return "GMT+"+hours}
        else{return "GMT"}

    }

    const checkIfClass = (timestamp,groupClasses) => 
    {
        var isClass = false
        var name = ""

        groupClasses.forEach(groupClass => {
            if(groupClass.time === timestamp)
            {
                isClass = true
                name = groupClass.name
            }
        });

        return{isClass:isClass,name:name}
    }

    return (
        <div className='bookingInput'>
            <div className='bookingColumn'>
                <span>Date</span>
                <div className={`bookingDropdown ${bookingObj.error?"error":""}`}>
                    <DatePicker className='datePicker' selected={startDate} minDate={moment().toDate()} placeholderText={"Select a date"} onChange={(date) => dateChanged(date)} dateFormat="dd-MM-yyyy"/>
                </div>
                {/* <input className='bookingDropdown' type="date" placeholder='Select Date'/> */}
                

            </div>
            <div className='bookingColumn'>
                <div className='bookingTitleRow'>
                    <span>Start Time</span>
                    {index === 0 && <span className="timezoneSpan">(All times are in {getTimeZone()})</span>}
                </div>
                <select className={`bookingDropdown ${bookingObj.error?"error":""}`} onChange={optionChanged} placeholder='Choose Time' value={selectedTime}>
                    <option value="" disabled selected>Select your option</option>
                    {sortOptions.map((option) => (
                        <option value={option.id}>{option.name}</option>
                    ))}
                </select>
            </div>
            {showBin && <img className='bookingBin' src='/assets/trash-can.png' onClick={deleteClicked }/>}
        </div>
    )
}
