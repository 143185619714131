import React, { useEffect, useState } from 'react';
import '../../../styles/dashboard.css'
import {useNavigate,useLocation,useParams} from 'react-router-dom';


export default function CreditBalance({balanceP,earningsP}) {

    const navigate = useNavigate();

    const [balance, setBalance] = useState(balanceP)
    const [earnings, setEarnings] = useState(earningsP)
    
    useEffect(() => {
        
        setBalance(balanceP)
        setEarnings(earningsP)
    
    },[balanceP,earningsP])


    
    const buyMoreClasses = () => {
        navigate("../buy", { replace: true });
    }

    return (
        <div className="classBalanceSection">
            <div className={`classBalanceTile ${balance>0?"":"error"}`}>
                <img className="classBalanceImg" src={balance>0?"/assets/classBalance.png":"/assets/classBalanceError.png"}/>
                <div className={`classBalanceColumn ${balance>0?"":"error"}`}>
                    <span className="classBalanceTitle">{balance>0?`${balance} Classes Remaining`:"Classes Exhausted"}</span>
                    <span>${earnings/100} Charged Through Current Cycle</span>
                </div>
            </div>
            <div className={`classBalanceBtn ${balance>0?"":"error"}`} onClick={buyMoreClasses}>Get More Classes</div>
        </div>
    )
}
