import React, { useEffect, useState } from 'react';
import '../../styles/practice.css'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import * as constants from '../../constants';
import { duration } from 'moment';


export default function AddTutorClass({tutor,added}) {

    const baseURL = process.env.REACT_APP_BASEURL;

    const [name,setName] = useState("")
    const [capacity,setCapacity] = useState("")
    const [duration,setDuration] = useState("")
    const [startDate, setStartDate] = useState(new Date());
    const [selectedTime, setSelectedTime] = useState("")

    const [nameError,setNameError] = useState(false)
    const [error, setError] = useState(false)

    const [showAdded,setShowAdded] = useState(false)
    const [adding,setShowAdding] = useState(false)

    const timeOptions = [
        '00:00','00:30',
        '01:00','01:30',
        '02:00','02:30',
        '03:00','03:30',
        '04:00','04:30',
        '05:00','05:30',
        '06:00','06:30',
        '07:00','07:30',
        '08:00','08:30',
        '09:00','09:30',
        '10:00','10:30',
        '11:00','11:30',
        '12:00','12:30',
        '13:00','13:30',
        '14:00','14:30',
        '15:00','15:30',
        '16:00','16:30',
        '17:00','17:30',
        '18:00','18:30',
        '19:00','19:30',
        '20:00','20:30',
        '21:00','21:30',
        '22:00','22:30',
        '23:00','23:30',
    ]


    const isDisabled = () => {

        console.log("CHECKIGN")

        if(name==="" || capacity==="" || duration==="" || selectedTime===""){return true}
        else if(adding){return true}
        else{return false}
    }

    const optionChanged = async(event) => {

        setSelectedTime(event.target.value)

    }

    const addNewClass = () => {

        setShowAdding(true)

        let date = new Date(startDate.getFullYear()+"-"+(startDate.getMonth()+1)+"-"+startDate.getDate()+" "+selectedTime)
        let id = Math.random().toString(16).slice(2)


        axios.post(baseURL+'createTutorClass',{
            id:id,
            business:constants.BUSINESS,
            name:name,
            startTime: date,
            tutorID:tutor.uid,
            tutorName:tutor.name,
            capacity:+capacity

        }).then((response) => {
            
            let lesson = createLessonObject(id,date)
            setShowAdding(false)
            setName("")
            setCapacity("")
            setDuration("")
            setStartDate(new Date())
            setShowAdded(true)
            added(lesson)
        })
    }


    const createLessonObject = (id,date) =>
    {
        let lesson = {
            id:id,
            isClass:true,
            students:[],
            studentNames:[],
            tutorID:tutor.uid,
            tutorName:tutor.name,
            capacity:capacity,
            duration:+duration,
            subject:name,

            scheduled:true,
            startTime:date,
        }

        return lesson
    }



    return(

        <div className='upcomingClasses'>

            <span className="dashboardSubTitle">Add a Class</span>
            <span className="timezoneSpan">Add a new class to your schedule for students to join</span>

        
                <div className='editAnswerRow addClass'>Class Name: <input className='bookingDropdown' type="text" placeholder='(eg. Maths Class)' value={name} onChange={(e) => setName(e.target.value)}/></div>
                <div className='editAnswerRow addClass'>Capacity: <input className='bookingDropdown' type="number" placeholder='Class Capacity' value={capacity} onChange={(e) => setCapacity(e.target.value)}/></div>
                <div className='editAnswerRow addClass'>Date: 
                    <div className={`bookingDropdown ${nameError?"error":""}`}>
                        <DatePicker className='datePicker' selected={startDate} onChange={(date) => setStartDate(date)} dateFormat="dd-MM-yyyy"/>
                    </div>
                    <select className={`bookingDropdown ${error?"error":""}`} onChange={optionChanged} placeholder='Choose Court' value={selectedTime}>
                        <option value="" disabled selected>Select your option</option>
                        {timeOptions.map((option) => (
                            <option value={option}>{option}</option>
                        ))}
                    </select>
                </div>
                <div className='editAnswerRow addClass'>Minutes Duration: <input className='bookingDropdown' type="number" placeholder='30' value={duration} onChange={(e) => setDuration(e.target.value)}/></div>
                
                <div>
                    <button className="availabilitySave" onClick={addNewClass} disabled={isDisabled()}>Save</button>
                    {showAdded && <span>Added</span>}
                    {adding && <span>Adding</span>}
                </div>
            

        </div>
    )
}