// export const MULTIPLE = false

// export const BUSINESS = "Caolan Software"
// export const LOGO = '/assets/logo.png'

// export const SUPPORT_EMAIL = 'help@caolansoftware.com'

// export const TUTOR_ID = "m0YBqV8L5fdzi9H4FzTrEWfkVwL2"
// export const BIO = "I studied Computer Science in Trinity College. After this, I became the Head of Software Development at SQUID.";
// export const LANGUAGES = ['English','Javascript'];
// export const SUBJECTS = ['Web Development'];
// export const NAME = "Caolan Melvin";
// export const PHOTO = '/assets/profilePic.jpeg'
// export const PREFERENCE = "Mid-Level Students";
// export const TITLE = "Tech Prodigy";
// export const START_RATE = 20;
// export const STRIPE = "acct_1LuK1cC7dQuPBvhw";
// export const VIDEO = "https://www.youtube.com/embed/R2pIutTspQA"

// export const SOCIAL_MEDIA = [
//     {type:"Instagram",value:"https://www.instagram.com/caolangm/"}
// ];

// export const OPTIONS = [
//     {id:"1",amountLesson:4,name:"Starter Pack",overallPrice:16000,pricePerLesson:4000}
// ];








export const MULTIPLE = false

export const BUSINESS = "Code Correctly"
export const LOGO = '/assets/logo.png'

export const SUPPORT_EMAIL = 'help@ivansoftware.com'

export const TUTOR_ID = "fhO3R1HLkibYo2LsNy3wGlcAa763"
export const BIO = "Ivan is a software engineer with more than a decade of experience working for tech companies in the United States. Whether it’s frontend (web and mobile apps), backend (APIs), or cloud, you’ll learn the tools and skills necessary to become a software engineer via project based learning.";
export const LANGUAGES = ['English'];
export const SUBJECTS = ['Frontend','Backend','Cloud'];
export const NAME = "Ivan Valle";
export const PHOTO = '/assets/ivan.png'
export const PREFERENCE = "Everyone is welcome";
export const TITLE = "Software Engineer";
export const START_RATE = 25;
export const STRIPE = "acct_1MlNT0GvRiyww0nZ";
export const VIDEO = "https://www.youtube.com/embed/R2pIutTspQA"

export const SOCIAL_MEDIA = [
    // {type:"Instagram",value:"https://www.instagram.com/caolangm/"}
];

export const OPTIONS = [
    {id:"1",amountLesson:1,duration:60,name:"Single Coding Class",subText:"4 - 6 students with project based learning",overallPrice:2500,pricePerLesson:2500},
    {id:"2",amountLesson:1,duration:30,name:"One on One Chat",subText:"One on one chat to get code help or talk about anything you want",overallPrice:2500,pricePerLesson:2500},
];