import { getAuth,signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import '../../styles/dashboard.css'
import AvailabiltyDay from "./AvailabilityDay";
import axios from "axios";

export default function AvailabilitySection({tutor}) {

    const baseURL = process.env.REACT_APP_BASEURL;


    const [title,setTitle] = useState("")
    
    const [daysList, setDaysList] = useState([0,1,2,3,4,5,6])


    console.log("TUTOR",tutor)

    const showDays = () => {

        let dayArray = []
        var index = 0


        while(index<7){
            let day = 
                <AvailabiltyDay 
                    index={index} 
                    tutor={tutor}
                    updated={dayUpdated}
                />
            dayArray.push(day)
            index++
        }

        return dayArray
    }
    

    const dayUpdated = (index,available,openHours,dayName) => {

        var times = null
        if(available){times = openHours}
        
        daysList[index] = {name:dayName,Available:available,Times:times}

        
    }



    const getTimeZone = () => {
        const offset = (new Date()).getTimezoneOffset();

        let hours = offset/-60

        if(hours < 0){return "GMT"+hours}
        else if(hours > 0){return "GMT+"+hours}
        else{return "GMT"}

    }

    const saveTimes = () => {
        addTimesToDB()
    }

    const addTimesToDB = () => {

        axios.post(baseURL+'editTutorAvailability',{
        
            tutorID:tutor.uid,
            times:daysList

        
        }).then((response) => {
                // setUpdatedSuccess(true)
            let resVar = response.data
            console.log("Response", resVar)

            var user = JSON.parse(localStorage.getItem("user"))
            user.availability = daysList
            localStorage.setItem("user",JSON.stringify(user))

        })
    }

    return(
        <div className="upcomingClasses">
            <span className="dashboardSubTitle">Availability through the week</span>
            <span className="timezoneSpan">All times are in {getTimeZone()}</span>
            
                <div className="availabilityGrid">
                    {showDays()}    
                </div>
                
                <button className="availabilitySave" onClick={saveTimes}>Save</button>
            
            
        </div>
    )
}


